import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed } from "vue";
import { PopoverPortal, PopoverContent, useForwardPropsEmits } from "radix-vue";
import { cn } from "@/lib/utils";
export default /*@__PURE__*/ _defineComponent({
    ...{ inheritAttrs: false },
    __name: 'PopoverContent',
    props: {
        forceMount: { type: Boolean },
        trapFocus: { type: Boolean },
        side: {},
        sideOffset: { default: 4 },
        align: { default: "center" },
        alignOffset: {},
        avoidCollisions: { type: Boolean },
        collisionBoundary: {},
        collisionPadding: {},
        arrowPadding: {},
        sticky: {},
        hideWhenDetached: { type: Boolean },
        updatePositionStrategy: {},
        prioritizePosition: { type: Boolean },
        asChild: { type: Boolean },
        as: {},
        disableOutsidePointerEvents: { type: Boolean },
        class: {}
    },
    emits: ["escapeKeyDown", "pointerDownOutside", "focusOutside", "interactOutside", "openAutoFocus", "closeAutoFocus"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emits = __emit;
        const delegatedProps = computed(() => {
            const { class: _, ...delegated } = props;
            return delegated;
        });
        const forwarded = useForwardPropsEmits(delegatedProps, emits);
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(PopoverPortal), null, {
                default: _withCtx(() => [
                    _createVNode(_unref(PopoverContent), _mergeProps({ ..._unref(forwarded), ..._ctx.$attrs }, {
                        class: _unref(cn)('tw-z-50 tw-w-72 tw-rounded-xl tw-bg-card tw-text-card-foreground tw-shadow-md tw-outline-none tw-border tw-border-[#F1F6F9] dark:tw-border-gray-700/30 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2', props.class)
                    }), {
                        default: _withCtx(() => [
                            _renderSlot(_ctx.$slots, "default")
                        ]),
                        _: 3
                    }, 16, ["class"])
                ]),
                _: 3
            }));
        };
    }
});
