import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "tw-relative" };
const _hoisted_2 = { class: "tw-w-[252px] tw-bg-[#111827] tw-fixed tw-top-0 tw-left-0 tw-z-40 tw-flex tw-justify-end" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SidebarNavigation = _resolveComponent("SidebarNavigation");
    const _component_CircleX = _resolveComponent("CircleX");
    const _component_ArrowLeftFromLine = _resolveComponent("ArrowLeftFromLine");
    return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
        _createElementVNode("div", {
            class: _normalizeClass(["tw-max-w-[280px] tw-min-w-[258px] tw-h-[calc(100vh-107px)] tw-overflow-auto sm:tw-block", {
                    'tw-hidden': !$setup.isSidebarVisible,
                    'sidebar-open-left': $setup.isSidebarVisible
                }])
        }, [
            _createVNode(_component_SidebarNavigation, { gameCategories: $props.gameCategories }, null, 8, ["gameCategories"]),
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("button", {
                    class: _normalizeClass(["tw-relative tw-right-2 tw-top-2", { '': $setup.isSidebarVisible }]),
                    onClick: _cache[0] || (_cache[0] =
                        //@ts-ignore
                        (...args) => ($setup.toggleSidebar && $setup.toggleSidebar(...args)))
                }, [
                    _createVNode(_component_CircleX)
                ], 2)
            ])
        ], 2),
        _createElementVNode("button", {
            class: _normalizeClass(["sm:tw-hidden tw-block tw-absolute tw-left-5 tw-top-0 secondary-sidebar-mob", { 'tw-hidden': $setup.isSidebarVisible }]),
            onClick: _cache[1] || (_cache[1] =
                //@ts-ignore
                (...args) => ($setup.toggleSidebar && $setup.toggleSidebar(...args)))
        }, [
            _createVNode(_component_ArrowLeftFromLine)
        ], 2)
    ]));
}
