import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "register_user" };
const _hoisted_2 = { class: "container" };
const _hoisted_3 = { class: "status-message" };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = {
    key: 1,
    class: "error"
};
const _hoisted_6 = {
    key: 2,
    class: "success"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                ($setup.loading)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_4, " Verifying token, please wait... "))
                    : _createCommentVNode("", true),
                ($setup.errorMessage)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString($setup.errorMessage), 1))
                    : _createCommentVNode("", true),
                ($setup.successMessage)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString($setup.successMessage), 1))
                    : _createCommentVNode("", true)
            ])
        ])
    ]));
}
