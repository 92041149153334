import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { Primitive } from "radix-vue";
import { buttonVariants } from ".";
import { cn } from "@/lib/utils";
export default /*@__PURE__*/ _defineComponent({
    __name: 'Button',
    props: {
        variant: {},
        size: {},
        color: {},
        class: {},
        asChild: { type: Boolean },
        as: { default: "button" }
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(Primitive), {
                as: _ctx.as,
                "as-child": _ctx.asChild,
                class: _normalizeClass(_unref(cn)(_unref(buttonVariants)({ variant: _ctx.variant, size: _ctx.size, color: _ctx.color }), props.class))
            }, {
                default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
            }, 8, ["as", "as-child", "class"]));
        };
    }
});
