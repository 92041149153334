import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue";
const _hoisted_1 = { class: "games-section" };
const _hoisted_2 = { class: "overlay pb-120" };
const _hoisted_3 = { class: "container pt-120" };
const _hoisted_4 = { class: "tab-content" };
const _hoisted_5 = {
    class: "tab-pane fade show active",
    id: "slots",
    role: "tabpanel",
    "aria-labelledby": "slots-tab"
};
const _hoisted_6 = { class: "tab-content" };
const _hoisted_7 = {
    class: "tab-pane fade show active",
    id: "slots-all",
    role: "tabpanel",
    "aria-labelledby": "slots-all-tab"
};
const _hoisted_8 = { class: "all-items" };
const _hoisted_9 = ["href"];
const _hoisted_10 = { class: "magnific-area position-relative d-flex align-items-center justify-content-around" };
const _hoisted_11 = { class: "bg-area" };
const _hoisted_12 = ["src", "alt"];
const _hoisted_13 = { class: "drops-wins" };
const _hoisted_14 = { class: "overlay pt-120 pb-120" };
const _hoisted_15 = { class: "container wow fadeInUp" };
const _hoisted_16 = { class: "row justify-content-between align-items-center" };
const _hoisted_17 = { class: "col-xxl-4 col-xl-5 col-md-6" };
const _hoisted_18 = { class: "right-box" };
const _hoisted_19 = { action: "#" };
const _hoisted_20 = { class: "btn-area mb-30 mt-60" };
const _hoisted_21 = { class: "link-area gap-1 d-flex align-items-center justify-content-center" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_link = _resolveComponent("router-link");
    return (_openBlock(), _createElementBlock("main", null, [
        _cache[20] || (_cache[20] = _createStaticVNode("<section class=\"banner-section inner-banner games\"><div class=\"overlay\"><div class=\"banner-content\"><div class=\"container\"><div class=\"row\"><div class=\"col-lg-8 col-md-10\"><div class=\"main-content\"><h1>Games</h1><div class=\"breadcrumb-area\"><nav aria-label=\"breadcrumb\"><ol class=\"breadcrumb d-flex align-items-center\"><li class=\"breadcrumb-item\"><a href=\"/\">Home</a></li><li class=\"breadcrumb-item active\" aria-current=\"page\">Games</li></ol></nav></div></div></div></div></div></div></div></section>", 1)),
        _createElementVNode("section", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "row justify-content-between align-items-center" }, [
                                _createElementVNode("div", { class: "col-xl-4 col-md-7" }, [
                                    _createElementVNode("ul", {
                                        class: "nav bottom-nav gap-3",
                                        role: "tablist"
                                    }, [
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link active",
                                                id: "slots-all-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#slots-all",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "slots-all",
                                                "aria-selected": "true"
                                            }, "All")
                                        ]),
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link",
                                                id: "slots-popular-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#slots-popular",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "slots-popular",
                                                "aria-selected": "false"
                                            }, "Popular")
                                        ]),
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link",
                                                id: "slots-new-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#slots-new",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "slots-new",
                                                "aria-selected": "false"
                                            }, "New")
                                        ])
                                    ])
                                ]),
                                _createElementVNode("div", { class: "col-xl-3 col-lg-4 col-md-5" }, [
                                    _createElementVNode("form", { action: "#" }, [
                                        _createElementVNode("div", { class: "form-group input-area d-flex align-items-center" }, [
                                            _createElementVNode("input", {
                                                type: "text",
                                                placeholder: "Search Game",
                                                autocomplete: "off"
                                            }),
                                            _createElementVNode("button", { class: "btn-area" }, [
                                                _createElementVNode("img", {
                                                    src: "/assets/images/icon/search-icon.png",
                                                    alt: "icon"
                                                })
                                            ])
                                        ])
                                    ])
                                ])
                            ], -1)),
                            _createElementVNode("div", _hoisted_6, [
                                _createElementVNode("div", _hoisted_7, [
                                    _createElementVNode("div", _hoisted_8, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allGames, (game) => {
                                            return (_openBlock(), _createElementBlock("div", {
                                                class: "single-item",
                                                key: game.id
                                            }, [
                                                _createElementVNode("a", {
                                                    href: $setup.user?.username ? `${_ctx.Const.URL}/game/${game.name}/?api_exit=/` : undefined,
                                                    target: "_blank",
                                                    onClick: _cache[0] || (_cache[0] = ($event) => (!$setup.user?.username && $event.preventDefault()))
                                                }, [
                                                    _createElementVNode("div", _hoisted_10, [
                                                        _createElementVNode("div", _hoisted_11, [
                                                            _createElementVNode("img", {
                                                                class: "bg-item",
                                                                src: `${_ctx.Const.URL}/frontend/Default/ico/${game.name}.jpg`,
                                                                alt: game.title
                                                            }, null, 8, _hoisted_12)
                                                        ])
                                                    ])
                                                ], 8, _hoisted_9)
                                            ]));
                                        }), 128))
                                    ]),
                                    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ], -1))
                                ]),
                                _cache[3] || (_cache[3] = _createElementVNode("div", {
                                    class: "tab-pane fade",
                                    id: "slots-popular",
                                    role: "tabpanel",
                                    "aria-labelledby": "slots-popular-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-1.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-2.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-3.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-4.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-6.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-7.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ], -1)),
                                _cache[4] || (_cache[4] = _createElementVNode("div", {
                                    class: "tab-pane fade",
                                    id: "slots-new",
                                    role: "tabpanel",
                                    "aria-labelledby": "slots-new-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ], -1))
                            ])
                        ]),
                        _cache[6] || (_cache[6] = _createElementVNode("div", {
                            class: "tab-pane fade",
                            id: "table",
                            role: "tabpanel",
                            "aria-labelledby": "table-tab"
                        }, [
                            _createElementVNode("div", { class: "row justify-content-between align-items-center" }, [
                                _createElementVNode("div", { class: "col-xl-4 col-md-7" }, [
                                    _createElementVNode("ul", {
                                        class: "nav bottom-nav gap-3",
                                        role: "tablist"
                                    }, [
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link active",
                                                id: "table-all-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#table-all",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "table-all",
                                                "aria-selected": "true"
                                            }, "All")
                                        ]),
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link",
                                                id: "table-popular-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#table-popular",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "table-popular",
                                                "aria-selected": "false"
                                            }, "Popular")
                                        ]),
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link",
                                                id: "table-new-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#table-new",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "table-new",
                                                "aria-selected": "false"
                                            }, "New")
                                        ])
                                    ])
                                ]),
                                _createElementVNode("div", { class: "col-xl-3 col-lg-4 col-md-5" }, [
                                    _createElementVNode("form", { action: "#" }, [
                                        _createElementVNode("div", { class: "form-group input-area d-flex align-items-center" }, [
                                            _createElementVNode("input", {
                                                type: "text",
                                                placeholder: "Search Game",
                                                autocomplete: "off"
                                            }),
                                            _createElementVNode("button", { class: "btn-area" }, [
                                                _createElementVNode("img", {
                                                    src: "/assets/images/icon/search-icon.png",
                                                    alt: "icon"
                                                })
                                            ])
                                        ])
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", { class: "tab-content" }, [
                                _createElementVNode("div", {
                                    class: "tab-pane fade show active",
                                    id: "table-all",
                                    role: "tabpanel",
                                    "aria-labelledby": "table-all-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-1.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-2.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-3.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-4.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-6.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-7.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ]),
                                _createElementVNode("div", {
                                    class: "tab-pane fade",
                                    id: "table-popular",
                                    role: "tabpanel",
                                    "aria-labelledby": "table-popular-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-1.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-2.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-3.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-4.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-6.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-7.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ]),
                                _createElementVNode("div", {
                                    class: "tab-pane fade",
                                    id: "table-new",
                                    role: "tabpanel",
                                    "aria-labelledby": "table-new-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ])
                            ])
                        ], -1)),
                        _cache[7] || (_cache[7] = _createElementVNode("div", {
                            class: "tab-pane fade",
                            id: "live",
                            role: "tabpanel",
                            "aria-labelledby": "live-tab"
                        }, [
                            _createElementVNode("div", { class: "row justify-content-between align-items-center" }, [
                                _createElementVNode("div", { class: "col-xl-4 col-md-7" }, [
                                    _createElementVNode("ul", {
                                        class: "nav bottom-nav gap-3",
                                        role: "tablist"
                                    }, [
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link active",
                                                id: "live-all-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#live-all",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "live-all",
                                                "aria-selected": "true"
                                            }, "All")
                                        ]),
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link",
                                                id: "live-popular-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#live-popular",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "live-popular",
                                                "aria-selected": "false"
                                            }, "Popular")
                                        ]),
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link",
                                                id: "live-new-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#live-new",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "live-new",
                                                "aria-selected": "false"
                                            }, "New")
                                        ])
                                    ])
                                ]),
                                _createElementVNode("div", { class: "col-xl-3 col-lg-4 col-md-5" }, [
                                    _createElementVNode("form", { action: "#" }, [
                                        _createElementVNode("div", { class: "form-group input-area d-flex align-items-center" }, [
                                            _createElementVNode("input", {
                                                type: "text",
                                                placeholder: "Search Game",
                                                autocomplete: "off"
                                            }),
                                            _createElementVNode("button", { class: "btn-area" }, [
                                                _createElementVNode("img", {
                                                    src: "/assets/images/icon/search-icon.png",
                                                    alt: "icon"
                                                })
                                            ])
                                        ])
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", { class: "tab-content" }, [
                                _createElementVNode("div", {
                                    class: "tab-pane fade show active",
                                    id: "live-all",
                                    role: "tabpanel",
                                    "aria-labelledby": "live-all-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-1.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-2.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-3.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-4.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-6.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-7.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ]),
                                _createElementVNode("div", {
                                    class: "tab-pane fade",
                                    id: "live-popular",
                                    role: "tabpanel",
                                    "aria-labelledby": "live-popular-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-1.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-2.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-3.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-4.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-6.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-7.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ]),
                                _createElementVNode("div", {
                                    class: "tab-pane fade",
                                    id: "live-new",
                                    role: "tabpanel",
                                    "aria-labelledby": "live-new-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ])
                            ])
                        ], -1)),
                        _cache[8] || (_cache[8] = _createElementVNode("div", {
                            class: "tab-pane fade",
                            id: "jackpot",
                            role: "tabpanel",
                            "aria-labelledby": "jackpot-tab"
                        }, [
                            _createElementVNode("div", { class: "row justify-content-between align-items-center" }, [
                                _createElementVNode("div", { class: "col-xl-4 col-md-7" }, [
                                    _createElementVNode("ul", {
                                        class: "nav bottom-nav gap-3",
                                        role: "tablist"
                                    }, [
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link active",
                                                id: "jackpot-all-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#jackpot-all",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "jackpot-all",
                                                "aria-selected": "true"
                                            }, "All")
                                        ]),
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link",
                                                id: "jackpot-popular-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#jackpot-popular",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "jackpot-popular",
                                                "aria-selected": "false"
                                            }, "Popular")
                                        ]),
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link",
                                                id: "jackpot-new-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#jackpot-new",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "jackpot-new",
                                                "aria-selected": "false"
                                            }, "New")
                                        ])
                                    ])
                                ]),
                                _createElementVNode("div", { class: "col-xl-3 col-lg-4 col-md-5" }, [
                                    _createElementVNode("form", { action: "#" }, [
                                        _createElementVNode("div", { class: "form-group input-area d-flex align-items-center" }, [
                                            _createElementVNode("input", {
                                                type: "text",
                                                placeholder: "Search Game",
                                                autocomplete: "off"
                                            }),
                                            _createElementVNode("button", { class: "btn-area" }, [
                                                _createElementVNode("img", {
                                                    src: "/assets/images/icon/search-icon.png",
                                                    alt: "icon"
                                                })
                                            ])
                                        ])
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", { class: "tab-content" }, [
                                _createElementVNode("div", {
                                    class: "tab-pane fade show active",
                                    id: "jackpot-all",
                                    role: "tabpanel",
                                    "aria-labelledby": "jackpot-all-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-1.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-2.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-3.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-4.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-6.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-7.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ]),
                                _createElementVNode("div", {
                                    class: "tab-pane fade",
                                    id: "jackpot-popular",
                                    role: "tabpanel",
                                    "aria-labelledby": "jackpot-popular-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-1.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-2.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-3.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-4.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-6.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-7.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ]),
                                _createElementVNode("div", {
                                    class: "tab-pane fade",
                                    id: "jackpot-new",
                                    role: "tabpanel",
                                    "aria-labelledby": "jackpot-new-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ])
                            ])
                        ], -1)),
                        _cache[9] || (_cache[9] = _createElementVNode("div", {
                            class: "tab-pane fade",
                            id: "tournament",
                            role: "tabpanel",
                            "aria-labelledby": "tournament-tab"
                        }, [
                            _createElementVNode("div", { class: "row justify-content-between align-items-center" }, [
                                _createElementVNode("div", { class: "col-xl-4 col-md-7" }, [
                                    _createElementVNode("ul", {
                                        class: "nav bottom-nav gap-3",
                                        role: "tablist"
                                    }, [
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link active",
                                                id: "tournament-all-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#tournament-all",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "tournament-all",
                                                "aria-selected": "true"
                                            }, "All")
                                        ]),
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link",
                                                id: "tournament-popular-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#tournament-popular",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "tournament-popular",
                                                "aria-selected": "false"
                                            }, "Popular")
                                        ]),
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link",
                                                id: "tournament-new-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#tournament-new",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "tournament-new",
                                                "aria-selected": "false"
                                            }, "New")
                                        ])
                                    ])
                                ]),
                                _createElementVNode("div", { class: "col-xl-3 col-lg-4 col-md-5" }, [
                                    _createElementVNode("form", { action: "#" }, [
                                        _createElementVNode("div", { class: "form-group input-area d-flex align-items-center" }, [
                                            _createElementVNode("input", {
                                                type: "text",
                                                placeholder: "Search Game",
                                                autocomplete: "off"
                                            }),
                                            _createElementVNode("button", { class: "btn-area" }, [
                                                _createElementVNode("img", {
                                                    src: "/assets/images/icon/search-icon.png",
                                                    alt: "icon"
                                                })
                                            ])
                                        ])
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", { class: "tab-content" }, [
                                _createElementVNode("div", {
                                    class: "tab-pane fade show active",
                                    id: "tournament-all",
                                    role: "tabpanel",
                                    "aria-labelledby": "tournament-all-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-1.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-2.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-3.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-4.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-6.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-7.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ]),
                                _createElementVNode("div", {
                                    class: "tab-pane fade",
                                    id: "tournament-popular",
                                    role: "tabpanel",
                                    "aria-labelledby": "tournament-popular-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-1.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-2.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-3.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-4.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-6.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-7.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ]),
                                _createElementVNode("div", {
                                    class: "tab-pane fade",
                                    id: "tournament-new",
                                    role: "tabpanel",
                                    "aria-labelledby": "tournament-new-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ])
                            ])
                        ], -1)),
                        _cache[10] || (_cache[10] = _createElementVNode("div", {
                            class: "tab-pane fade",
                            id: "promotions",
                            role: "tabpanel",
                            "aria-labelledby": "promotions-tab"
                        }, [
                            _createElementVNode("div", { class: "row justify-content-between align-items-center" }, [
                                _createElementVNode("div", { class: "col-xl-4 col-md-7" }, [
                                    _createElementVNode("ul", {
                                        class: "nav bottom-nav gap-3",
                                        role: "tablist"
                                    }, [
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link active",
                                                id: "promotions-all-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#promotions-all",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "promotions-all",
                                                "aria-selected": "true"
                                            }, "All")
                                        ]),
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link",
                                                id: "promotions-popular-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#promotions-popular",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "promotions-popular",
                                                "aria-selected": "false"
                                            }, "Popular")
                                        ]),
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link",
                                                id: "promotions-new-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#promotions-new",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "promotions-new",
                                                "aria-selected": "false"
                                            }, "New")
                                        ])
                                    ])
                                ]),
                                _createElementVNode("div", { class: "col-xl-3 col-lg-4 col-md-5" }, [
                                    _createElementVNode("form", { action: "#" }, [
                                        _createElementVNode("div", { class: "form-group input-area d-flex align-items-center" }, [
                                            _createElementVNode("input", {
                                                type: "text",
                                                placeholder: "Search Game",
                                                autocomplete: "off"
                                            }),
                                            _createElementVNode("button", { class: "btn-area" }, [
                                                _createElementVNode("img", {
                                                    src: "/assets/images/icon/search-icon.png",
                                                    alt: "icon"
                                                })
                                            ])
                                        ])
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", { class: "tab-content" }, [
                                _createElementVNode("div", {
                                    class: "tab-pane fade show active",
                                    id: "promotions-all",
                                    role: "tabpanel",
                                    "aria-labelledby": "promotions-all-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-1.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-2.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-3.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-4.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-6.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-7.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ]),
                                _createElementVNode("div", {
                                    class: "tab-pane fade",
                                    id: "promotions-popular",
                                    role: "tabpanel",
                                    "aria-labelledby": "promotions-popular-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-1.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-2.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-3.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-4.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-6.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-7.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ]),
                                _createElementVNode("div", {
                                    class: "tab-pane fade",
                                    id: "promotions-new",
                                    role: "tabpanel",
                                    "aria-labelledby": "promotions-new-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ])
                            ])
                        ], -1)),
                        _cache[11] || (_cache[11] = _createElementVNode("div", {
                            class: "tab-pane fade",
                            id: "instant-wins",
                            role: "tabpanel",
                            "aria-labelledby": "instant-wins-tab"
                        }, [
                            _createElementVNode("div", { class: "row justify-content-between align-items-center" }, [
                                _createElementVNode("div", { class: "col-xl-4 col-md-7" }, [
                                    _createElementVNode("ul", {
                                        class: "nav bottom-nav gap-3",
                                        role: "tablist"
                                    }, [
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link active",
                                                id: "instant-wins-all-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#instant-wins-all",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "instant-wins-all",
                                                "aria-selected": "true"
                                            }, "All")
                                        ]),
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link",
                                                id: "instant-wins-popular-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#instant-wins-popular",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "instant-wins-popular",
                                                "aria-selected": "false"
                                            }, "Popular")
                                        ]),
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link",
                                                id: "instant-wins-new-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#instant-wins-new",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "instant-wins-new",
                                                "aria-selected": "false"
                                            }, "New")
                                        ])
                                    ])
                                ]),
                                _createElementVNode("div", { class: "col-xl-3 col-lg-4 col-md-5" }, [
                                    _createElementVNode("form", { action: "#" }, [
                                        _createElementVNode("div", { class: "form-group input-area d-flex align-items-center" }, [
                                            _createElementVNode("input", {
                                                type: "text",
                                                placeholder: "Search Game",
                                                autocomplete: "off"
                                            }),
                                            _createElementVNode("button", { class: "btn-area" }, [
                                                _createElementVNode("img", {
                                                    src: "/assets/images/icon/search-icon.png",
                                                    alt: "icon"
                                                })
                                            ])
                                        ])
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", { class: "tab-content" }, [
                                _createElementVNode("div", {
                                    class: "tab-pane fade show active",
                                    id: "instant-wins-all",
                                    role: "tabpanel",
                                    "aria-labelledby": "instant-wins-all-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-1.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-2.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-3.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-4.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-6.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-7.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ]),
                                _createElementVNode("div", {
                                    class: "tab-pane fade",
                                    id: "instant-wins-popular",
                                    role: "tabpanel",
                                    "aria-labelledby": "instant-wins-popular-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-1.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-2.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-3.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-4.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-6.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-7.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ]),
                                _createElementVNode("div", {
                                    class: "tab-pane fade",
                                    id: "instant-wins-new",
                                    role: "tabpanel",
                                    "aria-labelledby": "instant-wins-new-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ])
                            ])
                        ], -1)),
                        _cache[12] || (_cache[12] = _createElementVNode("div", {
                            class: "tab-pane fade",
                            id: "others",
                            role: "tabpanel",
                            "aria-labelledby": "others-tab"
                        }, [
                            _createElementVNode("div", { class: "row justify-content-between align-items-center" }, [
                                _createElementVNode("div", { class: "col-xl-4 col-md-7" }, [
                                    _createElementVNode("ul", {
                                        class: "nav bottom-nav gap-3",
                                        role: "tablist"
                                    }, [
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link active",
                                                id: "others-all-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#others-all",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "others-all",
                                                "aria-selected": "true"
                                            }, "All")
                                        ]),
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link",
                                                id: "others-popular-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#others-popular",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "others-popular",
                                                "aria-selected": "false"
                                            }, "Popular")
                                        ]),
                                        _createElementVNode("li", {
                                            class: "nav-item",
                                            role: "presentation"
                                        }, [
                                            _createElementVNode("button", {
                                                class: "nav-link",
                                                id: "others-new-tab",
                                                "data-bs-toggle": "tab",
                                                "data-bs-target": "#others-new",
                                                type: "button",
                                                role: "tab",
                                                "aria-controls": "others-new",
                                                "aria-selected": "false"
                                            }, "New")
                                        ])
                                    ])
                                ]),
                                _createElementVNode("div", { class: "col-xl-3 col-lg-4 col-md-5" }, [
                                    _createElementVNode("form", { action: "#" }, [
                                        _createElementVNode("div", { class: "form-group input-area d-flex align-items-center" }, [
                                            _createElementVNode("input", {
                                                type: "text",
                                                placeholder: "Search Game",
                                                autocomplete: "off"
                                            }),
                                            _createElementVNode("button", { class: "btn-area" }, [
                                                _createElementVNode("img", {
                                                    src: "/assets/images/icon/search-icon.png",
                                                    alt: "icon"
                                                })
                                            ])
                                        ])
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", { class: "tab-content" }, [
                                _createElementVNode("div", {
                                    class: "tab-pane fade show active",
                                    id: "others-all",
                                    role: "tabpanel",
                                    "aria-labelledby": "others-all-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-1.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-2.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-3.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-4.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-6.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-7.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ]),
                                _createElementVNode("div", {
                                    class: "tab-pane fade",
                                    id: "others-popular",
                                    role: "tabpanel",
                                    "aria-labelledby": "others-popular-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-1.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-2.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-3.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-4.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-6.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-7.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ]),
                                _createElementVNode("div", {
                                    class: "tab-pane fade",
                                    id: "others-new",
                                    role: "tabpanel",
                                    "aria-labelledby": "others-new-tab"
                                }, [
                                    _createElementVNode("div", { class: "all-items" }, [
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-16.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-17.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-18.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-5.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-8.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-9.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-10.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-11.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-12.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-13.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-14.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ]),
                                        _createElementVNode("div", { class: "single-item" }, [
                                            _createElementVNode("div", { class: "magnific-area position-relative d-flex align-items-center justify-content-around" }, [
                                                _createElementVNode("div", { class: "bg-area" }, [
                                                    _createElementVNode("img", {
                                                        class: "bg-item",
                                                        src: "/assets/images/games-logo-15.png",
                                                        alt: "image"
                                                    })
                                                ]),
                                                _createElementVNode("a", {
                                                    class: "mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center",
                                                    href: "https://www.youtube.com/watch?v=Djz8Nc0Qxwk"
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: "/assets/images/icon/play-icon.png",
                                                        alt: "icon"
                                                    })
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "btn-area mt-3 text-center" }, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            class: "cmn-btn"
                                        }, " Load More ")
                                    ])
                                ])
                            ])
                        ], -1))
                    ])
                ])
            ])
        ]),
        _createElementVNode("section", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                        _cache[19] || (_cache[19] = _createStaticVNode("<div class=\"col-xxl-5 col-xl-6 col-md-6\"><div class=\"section-header\"><h5 class=\"sub-title\">Drops &amp; Wins ( Live Casino)</h5><h2 class=\"title\">Easy way for crypto Play</h2></div><h2 class=\"currency\">€500.000</h2><div class=\"countdown d-flex align-items-center\"><span>Finishes in:</span><h6><span class=\"days\">00</span><span class=\"ref\">Days:</span></h6><h6><span class=\"hours\">00</span><span class=\"ref\">Hrs:</span></h6><h6><span class=\"minutes\">00</span><span class=\"ref\">Mins</span></h6></div></div>", 1)),
                        _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("div", _hoisted_18, [
                                _cache[18] || (_cache[18] = _createElementVNode("h4", { class: "mb-30" }, "Deposit and Play", -1)),
                                _createElementVNode("form", _hoisted_19, [
                                    _cache[16] || (_cache[16] = _createStaticVNode("<div class=\"input-field thumb-select d-flex\"><input type=\"text\" placeholder=\"0.001\"><select><option value=\"1\">BTC</option><option value=\"2\">ETH</option><option value=\"3\">LTC</option></select></div>", 1)),
                                    _createElementVNode("div", _hoisted_20, [
                                        _createElementVNode("a", {
                                            href: "javascript:void(0)",
                                            onClick: _cache[1] || (_cache[1] = ($event) => ($setup.openPopup('register'))),
                                            class: "cmn-btn w-100"
                                        }, _cache[13] || (_cache[13] = [
                                            _createTextVNode(" Start Playing "),
                                            _createElementVNode("i", { class: "icon-d-right-arrow-2" }, null, -1)
                                        ]))
                                    ]),
                                    _cache[17] || (_cache[17] = _createElementVNode("p", null, "By Clicking you agree with our", -1)),
                                    _createElementVNode("div", _hoisted_21, [
                                        _createVNode(_component_router_link, { to: "/terms-of-service" }, {
                                            default: _withCtx(() => _cache[14] || (_cache[14] = [
                                                _createTextVNode(" Terms of Service ")
                                            ])),
                                            _: 1
                                        }),
                                        _createVNode(_component_router_link, { to: "/privacy-policy" }, {
                                            default: _withCtx(() => _cache[15] || (_cache[15] = [
                                                _createTextVNode(" Privacy Policy ")
                                            ])),
                                            _: 1
                                        })
                                    ])
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ])
    ]));
}
