<template>
  <div class="privacy_policy">
    privacy-policy
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped>
  .privacy_policy {
    padding-top: 136px;
    min-height: 620px;
  }
  </style>