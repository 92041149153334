import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "header-section tw-bg-red" };
const _hoisted_2 = { class: "overlay" };
const _hoisted_3 = { class: "container" };
const _hoisted_4 = { class: "row d-flex header-area" };
const _hoisted_5 = { class: "navbar navbar-expand-lg navbar-light" };
const _hoisted_6 = {
    key: 0,
    class: "sidebar-wrapper"
};
const _hoisted_7 = { class: "sidebar-nav" };
const _hoisted_8 = { class: "sidebar-actions" };
const _hoisted_9 = {
    id: "navbar-content",
    class: "collapse navbar-collapse justify-content-between"
};
const _hoisted_10 = { class: "navbar-nav mr-auto mb-2 mb-lg-0" };
const _hoisted_11 = { class: "right-area header-action d-flex align-items-center max-un" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_link = _resolveComponent("router-link");
    const _component_ProfilePopover = _resolveComponent("ProfilePopover");
    return (_openBlock(), _createElementBlock("header", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("nav", _hoisted_5, [
                        _createVNode(_component_router_link, {
                            to: "/",
                            class: "navbar-brand"
                        }, {
                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                                _createElementVNode("img", {
                                    src: "/assets/images/fav.png",
                                    class: "fav d-none d-lg-block d-xl-none",
                                    alt: "fav"
                                }, null, -1),
                                _createElementVNode("img", {
                                    src: "/assets/images/logo.png",
                                    class: "logo d-block d-lg-none d-xl-block",
                                    alt: "logo"
                                }, null, -1)
                            ])),
                            _: 1
                        }),
                        _createElementVNode("button", {
                            class: "navbar-toggler collapsed",
                            type: "button",
                            onClick: _cache[0] || (_cache[0] =
                                //@ts-ignore
                                (...args) => (_ctx.toggleSidebar && _ctx.toggleSidebar(...args)))
                        }, _cache[8] || (_cache[8] = [
                            _createElementVNode("i", { class: "fas fa-bars" }, null, -1)
                        ])),
                        (_ctx.showSidebar)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "sidebar-overlay",
                                onClick: _cache[1] || (_cache[1] =
                                    //@ts-ignore
                                    (...args) => (_ctx.toggleSidebar && _ctx.toggleSidebar(...args)))
                            }))
                            : _createCommentVNode("", true),
                        _createElementVNode("div", {
                            class: _normalizeClass(["sidebar-drawer", {
                                    'sideBar-open': _ctx.showSidebar,
                                    '': !_ctx.showSidebar,
                                }])
                        }, [
                            (_ctx.showSidebar)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                    _createElementVNode("button", {
                                        class: "close-btn",
                                        onClick: _cache[2] || (_cache[2] =
                                            //@ts-ignore
                                            (...args) => (_ctx.toggleSidebar && _ctx.toggleSidebar(...args)))
                                    }, " × "),
                                    _createElementVNode("ul", _hoisted_7, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navLinks, (link) => {
                                            return (_openBlock(), _createElementBlock("li", {
                                                key: link.label,
                                                class: "sidebar-item"
                                            }, [
                                                _createVNode(_component_router_link, {
                                                    to: link.to,
                                                    class: _normalizeClass(["sidebar-link", {
                                                            '!tw-text-[#A1ff00]': _ctx.isActive(link.route),
                                                            '!tw-text-white': !_ctx.isActive(link.route),
                                                        }])
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(link.label), 1)
                                                    ]),
                                                    _: 2
                                                }, 1032, ["to", "class"])
                                            ]));
                                        }), 128))
                                    ]),
                                    _createElementVNode("div", _hoisted_8, [
                                        (!_ctx.user?.username)
                                            ? (_openBlock(), _createElementBlock("button", {
                                                key: 0,
                                                type: "button",
                                                class: "login",
                                                onClick: _cache[3] || (_cache[3] = () => { _ctx.openPopup('login'); _ctx.toggleSidebar(); })
                                            }, " Sign In "))
                                            : _createCommentVNode("", true),
                                        (!_ctx.user?.username)
                                            ? (_openBlock(), _createElementBlock("button", {
                                                key: 1,
                                                type: "button",
                                                class: "cmn-btn reg",
                                                onClick: _cache[4] || (_cache[4] = () => { _ctx.openPopup('register'); _ctx.toggleSidebar(); })
                                            }, " Sign Up "))
                                            : _createCommentVNode("", true),
                                        (_ctx.user?.username)
                                            ? (_openBlock(), _createBlock(_component_ProfilePopover, {
                                                key: 2,
                                                user: _ctx.user,
                                                logout: _ctx.logout
                                            }, null, 8, ["user", "logout"]))
                                            : _createCommentVNode("", true)
                                    ])
                                ]))
                                : _createCommentVNode("", true)
                        ], 2),
                        _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("ul", _hoisted_10, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navLinks, (link) => {
                                    return (_openBlock(), _createElementBlock("li", {
                                        key: link.label,
                                        class: "tw-text-white"
                                    }, [
                                        _createVNode(_component_router_link, {
                                            to: link.to,
                                            class: _normalizeClass(["nav-link tw-font-semibold tw-bg-transparent tw-px-2.5", {
                                                    '!tw-text-[#A1ff00]': _ctx.isActive(link.route),
                                                    '!tw-text-white': !_ctx.isActive(link.route),
                                                }])
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(link.label), 1)
                                            ]),
                                            _: 2
                                        }, 1032, ["to", "class"])
                                    ]));
                                }), 128))
                            ]),
                            _createElementVNode("div", _hoisted_11, [
                                (!_ctx.user?.username)
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 0,
                                        type: "button",
                                        class: "login",
                                        "data-bs-toggle": "modal",
                                        "data-bs-target": "#loginMod",
                                        onClick: _cache[5] || (_cache[5] = ($event) => (_ctx.openPopup('login')))
                                    }, " Sign In "))
                                    : _createCommentVNode("", true),
                                (!_ctx.user?.username)
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 1,
                                        type: "button",
                                        class: "cmn-btn reg",
                                        "data-bs-toggle": "modal",
                                        "data-bs-target": "#registerMod",
                                        onClick: _cache[6] || (_cache[6] = ($event) => (_ctx.openPopup('register')))
                                    }, " Sign Up "))
                                    : _createCommentVNode("", true),
                                (_ctx.user?.username)
                                    ? (_openBlock(), _createBlock(_component_ProfilePopover, {
                                        key: 2,
                                        user: _ctx.user,
                                        logout: _ctx.logout
                                    }, null, 8, ["user", "logout"]))
                                    : _createCommentVNode("", true)
                            ])
                        ])
                    ])
                ])
            ])
        ])
    ]));
}
