<template>
  <div class="terms-of-service">
    Terms of Service
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped>
.terms-of-service {
    padding-top: 136px;
    min-height: 620px;
}
</style>
