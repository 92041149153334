import { createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "log-reg" };
const _hoisted_2 = { class: "container" };
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col-lg-6" };
const _hoisted_5 = {
    class: "modal fade show",
    id: "welcomeBonusMod",
    "aria-modal": "true",
    role: "dialog",
    style: { "display": "block" }
};
const _hoisted_6 = { class: "modal-dialog modal-dialog-centered" };
const _hoisted_7 = { class: "modal-content" };
const _hoisted_8 = { class: "modal-header justify-content-center" };
const _hoisted_9 = { class: "tab-content" };
const _hoisted_10 = {
    class: "tab-pane fade show active",
    id: "loginArea",
    role: "tabpanel",
    "aria-labelledby": "loginArea-tab"
};
const _hoisted_11 = { class: "login-reg-content" };
const _hoisted_12 = { class: "modal-body" };
const _hoisted_13 = { class: "form-area" };
const _hoisted_14 = { action: "#" };
const _hoisted_15 = { class: "d-grid bonus-container gap-2" };
const _hoisted_16 = { class: "d-flex gap-2 align-items-center bonus-item" };
const _hoisted_17 = { class: "d-flex gap-2 align-items-center bonus-item" };
const _hoisted_18 = { class: "d-flex gap-2 align-items-center bonus-item" };
const _hoisted_19 = { class: "d-flex gap-2 align-items-center bonus-item" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, [
                                _createElementVNode("div", _hoisted_8, [
                                    _createElementVNode("button", {
                                        type: "button",
                                        onClick: _cache[0] || (_cache[0] =
                                            //@ts-ignore
                                            (...args) => ($options.closePopup && $options.closePopup(...args))),
                                        class: "btn-close",
                                        "data-bs-dismiss": "modal",
                                        "aria-label": "Close"
                                    })
                                ]),
                                _createElementVNode("div", _hoisted_9, [
                                    _createElementVNode("div", _hoisted_10, [
                                        _createElementVNode("div", _hoisted_11, [
                                            _createElementVNode("div", _hoisted_12, [
                                                _createElementVNode("div", _hoisted_13, [
                                                    _createElementVNode("form", _hoisted_14, [
                                                        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "section-text text-center" }, [
                                                            _createElementVNode("h4", { class: "title text-start" }, " Choose your Welcome Bonus ")
                                                        ], -1)),
                                                        _createElementVNode("div", _hoisted_15, [
                                                            _createElementVNode("label", _hoisted_16, [
                                                                _withDirectives(_createElementVNode("input", {
                                                                    type: "radio",
                                                                    name: "bonusType",
                                                                    class: "bonus-radio",
                                                                    value: "Casino",
                                                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.bonusType) = $event))
                                                                }, null, 512), [
                                                                    [_vModelRadio, _ctx.bonusType]
                                                                ]),
                                                                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-white" }, [
                                                                    _createElementVNode("p", null, "Casino"),
                                                                    _createElementVNode("b", null, "Up to €1,750 + 150 FS + 1 Bonus Crab in 3 bonuses")
                                                                ], -1))
                                                            ]),
                                                            _createElementVNode("label", _hoisted_17, [
                                                                _withDirectives(_createElementVNode("input", {
                                                                    type: "radio",
                                                                    name: "bonusType",
                                                                    class: "bonus-radio",
                                                                    value: "Sport",
                                                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.bonusType) = $event))
                                                                }, null, 512), [
                                                                    [_vModelRadio, _ctx.bonusType]
                                                                ]),
                                                                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-white" }, [
                                                                    _createElementVNode("p", null, "Sport"),
                                                                    _createElementVNode("b", null, "100% up to €200")
                                                                ], -1))
                                                            ]),
                                                            _createElementVNode("label", _hoisted_18, [
                                                                _withDirectives(_createElementVNode("input", {
                                                                    type: "radio",
                                                                    name: "bonusType",
                                                                    class: "bonus-radio",
                                                                    value: "Promocode",
                                                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.bonusType) = $event))
                                                                }, null, 512), [
                                                                    [_vModelRadio, _ctx.bonusType]
                                                                ]),
                                                                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-white w-100" }, [
                                                                    _createElementVNode("p", null, "I have a promocode"),
                                                                    _createElementVNode("input", {
                                                                        type: "text",
                                                                        class: "promocode-input mt-2",
                                                                        placeholder: "Enter promocode"
                                                                    })
                                                                ], -1))
                                                            ]),
                                                            _createElementVNode("label", _hoisted_19, [
                                                                _withDirectives(_createElementVNode("input", {
                                                                    type: "radio",
                                                                    name: "bonusType",
                                                                    class: "bonus-radio",
                                                                    value: "None",
                                                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.bonusType) = $event))
                                                                }, null, 512), [
                                                                    [_vModelRadio, _ctx.bonusType]
                                                                ]),
                                                                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-white" }, [
                                                                    _createElementVNode("p", null, "I do not want a Welcome Bonus")
                                                                ], -1))
                                                            ])
                                                        ]),
                                                        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "btn-area text-center" }, [
                                                            _createElementVNode("a", {
                                                                href: "javascript:void(0)",
                                                                class: "w-100 cmn-btn mt-4"
                                                            }, "Choose ")
                                                        ], -1))
                                                    ])
                                                ])
                                            ])
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ])
    ]));
}
