import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = { class: "log-reg" };
const _hoisted_2 = { class: "container" };
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col-lg-6" };
const _hoisted_5 = {
    id: "withdrawModal",
    class: "modal fade show",
    "aria-modal": "true",
    role: "dialog",
    style: { "display": "block" }
};
const _hoisted_6 = { class: "modal-dialog modal-dialog-centered" };
const _hoisted_7 = { class: "modal-content" };
const _hoisted_8 = { class: "modal-header justify-content-center" };
const _hoisted_9 = { class: "tab-content" };
const _hoisted_10 = {
    id: "loginArea",
    class: "tab-pane fade show active",
    role: "tabpanel",
    "aria-labelledby": "loginArea-tab"
};
const _hoisted_11 = { class: "login-reg-content" };
const _hoisted_12 = { class: "modal-body" };
const _hoisted_13 = { class: "form-area" };
const _hoisted_14 = { class: "tw-space-y-5" };
const _hoisted_15 = { class: "tw-flex tw-justify-between tw-w-full tw-gap-5" };
const _hoisted_16 = { class: "" };
const _hoisted_17 = { class: "tw-w-auto tw-relative" };
const _hoisted_18 = ["value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_TextField = _resolveComponent("TextField");
    const _component_Button = _resolveComponent("Button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, [
                                _createElementVNode("div", _hoisted_8, [
                                    _createElementVNode("button", {
                                        type: "button",
                                        class: "btn-close",
                                        "data-bs-dismiss": "modal",
                                        "aria-label": "Close",
                                        onClick: _cache[0] || (_cache[0] =
                                            //@ts-ignore
                                            (...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
                                    })
                                ]),
                                _createElementVNode("div", _hoisted_9, [
                                    _createElementVNode("div", _hoisted_10, [
                                        _createElementVNode("div", _hoisted_11, [
                                            _createElementVNode("div", _hoisted_12, [
                                                _createElementVNode("div", _hoisted_13, [
                                                    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "section-text tw-text-left tw-mb-6" }, [
                                                        _createElementVNode("h3", { class: "title tw-text-xl tw-leading-normal" }, " Withdraw "),
                                                        _createElementVNode("p", { class: "tw-text-xs tw-leading-normal" }, " Please note there is a minimum 100 EUR withdraw amount your balance must be MIN 100 USD or EURO! ")
                                                    ], -1)),
                                                    _createElementVNode("form", {
                                                        onSubmit: _cache[2] || (_cache[2] = _withModifiers(
                                                        //@ts-ignore
                                                        (...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
                                                    }, [
                                                        _createElementVNode("div", _hoisted_14, [
                                                            _createElementVNode("div", _hoisted_15, [
                                                                _createElementVNode("div", _hoisted_16, [
                                                                    _createElementVNode("div", _hoisted_17, [
                                                                        _withDirectives(_createElementVNode("select", {
                                                                            id: "txtcurrency",
                                                                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.selectedCurrency) = $event)),
                                                                            name: "txtcurrency",
                                                                            class: "tw-bg-[#c3c6cd] tw-text-[#070c19cc] tw-py-[13px] tw-pl-3.5 tw-pr-8 tw-border-none tw-border-gray-300 tw-rounded-xl tw-shadow-sm focus:tw-border-primary focus:tw-ring focus:tw-ring-primary focus:tw-ring-opacity-50 tw-appearance-none"
                                                                        }, [
                                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currencyOptions, (currency) => {
                                                                                return (_openBlock(), _createElementBlock("option", {
                                                                                    key: currency,
                                                                                    value: currency
                                                                                }, _toDisplayString(currency), 9, _hoisted_18));
                                                                            }), 128))
                                                                        ], 512), [
                                                                            [_vModelSelect, _ctx.selectedCurrency]
                                                                        ]),
                                                                        _cache[3] || (_cache[3] = _createElementVNode("svg", {
                                                                            xmlns: "http://www.w3.org/2000/svg",
                                                                            fill: "none",
                                                                            viewBox: "0 0 24 24",
                                                                            "stroke-width": "1.5",
                                                                            stroke: "#fff",
                                                                            class: "size-6 tw-absolute tw-right-2.5 tw-top-5 tw-w-4 tw-pointer-events-none"
                                                                        }, [
                                                                            _createElementVNode("path", {
                                                                                "stroke-linecap": "round",
                                                                                "stroke-linejoin": "round",
                                                                                d: "m19.5 8.25-7.5 7.5-7.5-7.5"
                                                                            })
                                                                        ], -1))
                                                                    ])
                                                                ]),
                                                                _createVNode(_component_TextField, {
                                                                    id: "txtamount",
                                                                    name: "txtamount",
                                                                    type: "number",
                                                                    placeholder: "Enter Amount",
                                                                    class: "tw-w-full tw-rounded-xl crypto_payment",
                                                                    inputClass: "tw-p-4 tw-text-[#070c19cc] !tw-bg-[#c3c6cd] tw-border-none tw-rounded-xl tw-placeholder-gray-500 tw-placeholder-opacity-50"
                                                                })
                                                            ]),
                                                            _createElementVNode("div", null, [
                                                                _createVNode(_component_TextField, {
                                                                    id: "wallet",
                                                                    name: "wallet",
                                                                    type: "text",
                                                                    placeholder: "Wallet Address",
                                                                    class: "tw-w-full tw-rounded-xl crypto_payment",
                                                                    inputClass: "tw-p-4 tw-text-[#070c19cc] !tw-bg-[#c3c6cd] tw-border-none tw-rounded-xl tw-placeholder-gray-500 tw-placeholder-opacity-50"
                                                                })
                                                            ]),
                                                            _createVNode(_component_Button, {
                                                                disabled: _ctx.isSubmitting,
                                                                type: "submit",
                                                                class: "tw-mt-4 tw-w-full tw-rounded-xl tw-h-12 tw-bg-[linear-gradient(107.15deg,_#0095ff,_#0855c4)] tw-text-white hover:tw-bg-primary/90",
                                                                variant: "default",
                                                                size: "default",
                                                                color: "default"
                                                            }, {
                                                                default: _withCtx(() => _cache[4] || (_cache[4] = [
                                                                    _createTextVNode(" Save ")
                                                                ])),
                                                                _: 1
                                                            }, 8, ["disabled"])
                                                        ])
                                                    ], 32)
                                                ])
                                            ])
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ])
    ]));
}
