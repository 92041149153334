import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
const _hoisted_1 = { class: "tw-flex tw-items-center tw-gap-2 tw-py-2 tw-pl-3 tw-pr-2 tw-rounded-full tw-cursor-pointer tw-bg-hover tw-justify-between sm:tw-justify-[unset]" };
const _hoisted_2 = { class: "tw-text-[13px] tw-font-semibold tw-text-nowrap" };
const _hoisted_3 = { class: "tw-text-sm tw-cursor-pointer" };
const _hoisted_4 = { class: "tw-px-5 tw-py-2 tw-transition-all hover:tw-bg-hover" };
// import { useRoute, useRouter } from "vue-router";
// SHADCDN COMPONENTS
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover/index";
// CUSTOM COMPONENT
import Icon from "@/components/icon.vue";
// AUTH COMPOSABLE
// import { useAuth } from "@/auth/useAuth";
// Define the `user` prop here
/* eslint-disable */
import { usePopupStore } from '@/stores/popup';
const __default__ = {
    methods: {
        openPopup(popup) {
            const popupStore = usePopupStore();
            console.log('popup: ', popup);
            popupStore.showPopup(popup);
        },
    }
};
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    __name: 'ProfilePopover',
    props: {
        user: {
            type: Object,
            required: true,
        },
        logout: {
            type: Function,
            required: true,
        },
    },
    setup(__props) {
        const props = __props;
        // const route = useRoute();
        // const router = useRouter();
        // const { user, logout } = useAuth();
        const handleLogout = async () => {
            await props.logout();
            // router.replace({
            //   name: "Login",
            //   query: { redirect: route.fullPath }
            // });
        };
        return (_ctx, _cache) => {
            const _component_router_link = _resolveComponent("router-link");
            return (_openBlock(), _createBlock(_unref(Popover), null, {
                default: _withCtx(() => [
                    _createVNode(_unref(PopoverTrigger), { "as-child": "" }, {
                        default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, [
                                _createElementVNode("p", _hoisted_2, " Hi. " + _toDisplayString(props.user?.username || ""), 1),
                                _createVNode(_unref(Avatar), {
                                    size: "xs",
                                    class: "tw-border tw-rounded-2xl tw-w-5 tw-h-5 md:tw-w-6 md:tw-h-6 tw-flex tw-items-center tw-justify-center"
                                }, {
                                    default: _withCtx(() => [
                                        (props.user?.photoURL)
                                            ? (_openBlock(), _createBlock(_unref(AvatarImage), {
                                                key: 0,
                                                src: props.user.photoURL,
                                                alt: "User"
                                            }, null, 8, ["src"]))
                                            : _createCommentVNode("", true),
                                        _createVNode(_unref(AvatarFallback), { class: "tw-text-black" }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(props.user?.username?.charAt(0).toUpperCase()), 1)
                                            ]),
                                            _: 1
                                        })
                                    ]),
                                    _: 1
                                })
                            ])
                        ]),
                        _: 1
                    }),
                    _createVNode(_unref(PopoverContent), { class: "tw-w-[200px] tw-py-2 tw-px-0 tw-rounded-lg !tw-z-[999]" }, {
                        default: _withCtx(() => [
                            _createElementVNode("ul", _hoisted_3, [
                                _createElementVNode("li", {
                                    "data-bs-toggle": "modal",
                                    "data-bs-target": "#depositModal",
                                    class: "tw-flex tw-items-center tw-gap-2 tw-px-5 tw-py-2 tw-transition-all hover:tw-bg-hover",
                                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.openPopup('deposit')))
                                }, [
                                    _createVNode(Icon, {
                                        name: "User",
                                        size: 18,
                                        class: "tw-text-muted"
                                    }),
                                    _cache[3] || (_cache[3] = _createTextVNode(" Deposit "))
                                ]),
                                _createElementVNode("li", {
                                    "data-bs-toggle": "modal",
                                    "data-bs-target": "#withdrawModal",
                                    class: "tw-flex tw-items-center tw-gap-2 tw-px-5 tw-py-2 tw-transition-all hover:tw-bg-hover",
                                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.openPopup('withdraw')))
                                }, [
                                    _createVNode(Icon, {
                                        name: "User",
                                        size: 18,
                                        class: "tw-text-muted"
                                    }),
                                    _cache[4] || (_cache[4] = _createTextVNode(" Withdraw "))
                                ]),
                                _createElementVNode("li", _hoisted_4, [
                                    _createVNode(_component_router_link, {
                                        to: "/history",
                                        class: "tw-flex tw-items-center tw-gap-2"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(Icon, {
                                                name: "Settings",
                                                size: 18,
                                                class: "tw-text-muted"
                                            }),
                                            _cache[5] || (_cache[5] = _createTextVNode(" History "))
                                        ]),
                                        _: 1
                                    })
                                ]),
                                _cache[7] || (_cache[7] = _createElementVNode("li", { class: "py-1" }, [
                                    _createElementVNode("hr", { class: "tw-border-0 tw-border-b tw-border-b-gray-200 dark:tw-border-b-gray-700" })
                                ], -1)),
                                _createElementVNode("li", {
                                    class: "tw-flex tw-items-center tw-gap-2 tw-px-5 tw-py-2 tw-transition-all hover:tw-bg-hover",
                                    onClick: _cache[2] || (_cache[2] = ($event) => (handleLogout()))
                                }, [
                                    _createVNode(Icon, {
                                        name: "LogOut",
                                        size: 18,
                                        class: "tw-text-muted"
                                    }),
                                    _cache[6] || (_cache[6] = _createTextVNode(" Log Out "))
                                ])
                            ])
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }));
        };
    }
});
