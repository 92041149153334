import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue";
const _hoisted_1 = { class: "total-jackpot" };
const _hoisted_2 = { class: "overlay pt-120 pb-120" };
const _hoisted_3 = { class: "container wow fadeInUp" };
const _hoisted_4 = { class: "row justify-content-center" };
const _hoisted_5 = { class: "col-lg-6" };
const _hoisted_6 = { class: "section-area text-center" };
const _hoisted_7 = { class: "btn-area mt-40" };
const _hoisted_8 = { class: "col-lg-6" };
const _hoisted_9 = { class: "jackpot-carousel" };
const _hoisted_10 = { class: "single-slide" };
const _hoisted_11 = { class: "box" };
const _hoisted_12 = { class: "img-box" };
const _hoisted_13 = ["src", "alt"];
const _hoisted_14 = { class: "img-box" };
const _hoisted_15 = ["src", "alt"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_link = _resolveComponent("router-link");
    const _component_Slide = _resolveComponent("Slide");
    const _component_Carousel = _resolveComponent("Carousel");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                            _cache[1] || (_cache[1] = _createElementVNode("h5", { class: "sub-title" }, "Latest Winners", -1)),
                            _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "title" }, "122 163 738,00 EUR", -1)),
                            _createElementVNode("div", _hoisted_7, [
                                _createVNode(_component_router_link, {
                                    to: "/games",
                                    class: "cmn-btn"
                                }, {
                                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                                        _createTextVNode(" All Jackpot Games "),
                                        _createElementVNode("i", { class: "icon-d-right-arrow-2" }, null, -1)
                                    ])),
                                    _: 1
                                })
                            ]),
                            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "icon-img" }, [
                                _createElementVNode("img", {
                                    src: "/assets/images/jackpot-icon.png",
                                    alt: "icon"
                                })
                            ], -1))
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "title mb-20 d-flex justify-content-between" }, [
                            _createElementVNode("h4", null, "Jackpot Games"),
                            _createElementVNode("a", { href: "javascript:void(0)" }, "Show All")
                        ], -1)),
                        _createElementVNode("div", _hoisted_9, [
                            _createVNode(_component_Carousel, _normalizeProps(_guardReactiveProps($data.config)), {
                                default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.games, (game) => {
                                        return (_openBlock(), _createBlock(_component_Slide, {
                                            key: game.id,
                                            class: "single"
                                        }, {
                                            default: _withCtx(() => [
                                                _createElementVNode("div", _hoisted_10, [
                                                    _createElementVNode("div", _hoisted_11, [
                                                        _createElementVNode("div", _hoisted_12, [
                                                            _createElementVNode("img", {
                                                                src: `${$data.Const.URL}/frontend/Default/ico/${game.name}.jpg`,
                                                                alt: game.title
                                                            }, null, 8, _hoisted_13)
                                                        ]),
                                                        _createElementVNode("div", _hoisted_14, [
                                                            _createElementVNode("img", {
                                                                src: `${$data.Const.URL}/frontend/Default/ico/${game.name}.jpg`,
                                                                alt: game.title
                                                            }, null, 8, _hoisted_15)
                                                        ])
                                                    ])
                                                ])
                                            ]),
                                            _: 2
                                        }, 1024));
                                    }), 128))
                                ]),
                                _: 1
                            }, 16)
                        ])
                    ])
                ])
            ])
        ])
    ]));
}
