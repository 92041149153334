import { defineStore } from 'pinia';
export const usePopupStore = defineStore('popup', {
    state: () => ({
        activePopup: null,
    }),
    actions: {
        showPopup(popupType) {
            this.activePopup = popupType;
        },
        hidePopup() {
            this.activePopup = null;
        },
    },
});
