import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { PopoverRoot, useForwardPropsEmits } from 'radix-vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'Popover',
    props: {
        defaultOpen: { type: Boolean },
        open: { type: Boolean },
        modal: { type: Boolean }
    },
    emits: ["update:open"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emits = __emit;
        const forwarded = useForwardPropsEmits(props, emits);
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(PopoverRoot), _normalizeProps(_guardReactiveProps(_unref(forwarded))), {
                default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
            }, 16));
        };
    }
});
