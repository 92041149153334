import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "casino_all" };
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useCasinoStore } from "@/stores/casino";
import CasinoComponent from "@/components/Casino/CasinoComponent.vue";
// Setup reactive variables and methods
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoPage',
    setup(__props) {
        const casinoStore = useCasinoStore();
        const route = useRoute();
        const games = computed(() => casinoStore.games);
        const gameCategories = computed(() => casinoStore.gameCategories);
        const defaultObject = {
            title: "All",
            category_id: "all", // "all" to indicate all games
            href: "all",
            count: 0, // Default count for the "all" category
        };
        const selectedCategory = ref(defaultObject);
        const isLoading = ref(false); // Track the loading state
        const loadMoreGames = async () => {
            isLoading.value = true; // Set loading to true when fetching games
            // setTimeout(async () => {
            const categoryId = selectedCategory.value?.category_id;
            await casinoStore.fetchGames(casinoStore.page + 1, categoryId);
            casinoStore.incrementPage();
            isLoading.value = false; // Set loading to false once the fetch is complete
            // }, 2000);
        };
        const initializeData = async () => {
            casinoStore.clearGames(); // Assuming `clearGames` is a method to empty the `games` array
            isLoading.value = true;
            // Fetch initial game categories if not already fetched
            if (casinoStore.gameCategories.length === 0) {
                await casinoStore.fetchGameCategories();
            }
            // Get the current route parameter (href)
            const href = route.params.id || "all"; // Default to "all" if no param is provided
            // Find matching category or reset to "all"
            const category = casinoStore.gameCategories.find((category) => category.href === href);
            selectedCategory.value = category || defaultObject;
            // Delay for 2 seconds, then fetch the games
            // setTimeout(async () => {
            const categoryId = selectedCategory.value.category_id;
            await casinoStore.fetchGames(0, categoryId);
            isLoading.value = false; // Set loading to false after fetching data
            // }, 2000);
        };
        // Watch for route changes to reinitialize data
        watch(() => route.params.id, initializeData);
        // Call initialization immediately
        initializeData();
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(CasinoComponent, {
                    games: games.value,
                    gameCategories: gameCategories.value,
                    selectedCategory: selectedCategory.value,
                    isLoading: isLoading.value,
                    onViewGames: loadMoreGames
                }, null, 8, ["games", "gameCategories", "selectedCategory", "isLoading"])
            ]));
        };
    }
});
