import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "tw-flex" };
const _hoisted_2 = { class: "tw-px-4 md:tw-px-6 tw-mx-auto tw-max-w-screen-2xl sm:tw-w-[calc(100%-280px)] tw-w-full pt-3 sm:tw-mt-0 tw-mt-5" };
const _hoisted_3 = { class: "tw-w-full" };
const _hoisted_4 = ["onClick"];
const _hoisted_5 = { class: "tw-space-y-1" };
const _hoisted_6 = { class: "tw-text-base tw-font-semibold" };
const _hoisted_7 = { class: "tw-pt-1 tw-text-base tw-font-semibold" };
const _hoisted_8 = { class: "tw-flex tw-justify-center tw-items-center" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_CasinoComponentSidebar = _resolveComponent("CasinoComponentSidebar");
    const _component_CasinoHeader = _resolveComponent("CasinoHeader");
    const _component_CardTitle = _resolveComponent("CardTitle");
    const _component_AvatarImage = _resolveComponent("AvatarImage");
    const _component_AvatarFallback = _resolveComponent("AvatarFallback");
    const _component_Avatar = _resolveComponent("Avatar");
    const _component_CardContent = _resolveComponent("CardContent");
    const _component_Icon = _resolveComponent("Icon");
    const _component_Button = _resolveComponent("Button");
    const _component_Card = _resolveComponent("Card");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_CasinoComponentSidebar, { gameCategories: $props.gameCategories }, null, 8, ["gameCategories"]),
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_CasinoHeader, {
                games: $props.games,
                isLoading: $props.isLoading
            }, null, 8, ["games", "isLoading"]),
            _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_Card, { class: "tw-border-none tw-py-6 tw-col-span-full tw-xl:col-span-4 tw-w-full tw-bg-transparent" }, {
                    default: _withCtx(() => [
                        _createVNode(_component_CardTitle, { class: "tw-mb-4 tw-text-lg" }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString($props.selectedCategory?.title || "All"), 1)
                            ]),
                            _: 1
                        }),
                        ($props.games.length)
                            ? (_openBlock(), _createBlock(_component_CardContent, {
                                key: 0,
                                class: "tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 xl:tw-grid-cols-3 2xl:tw-grid-cols-4 tw-gap-4"
                            }, {
                                default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.games, (game) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                            key: game.id
                                        }, [
                                            _createElementVNode("div", {
                                                onClick: ($event) => ($options.handleGameClick(game)),
                                                class: "tw-flex tw-items-center tw-gap-4 2xl:tw-gap-3 tw-border tw-border-white tw-rounded tw-p-3 xl:tw-py-3 xl:tw-px-1.5 2xl:tw-p-3 tw-cursor-pointer"
                                            }, [
                                                _createVNode(_component_Avatar, {
                                                    shape: "square",
                                                    size: "base"
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createVNode(_component_AvatarImage, {
                                                            src: `https://azurecoinz.com/frontend/Default/ico/${game.name}.jpg`,
                                                            alt: "game"
                                                        }, null, 8, ["src"]),
                                                        _createVNode(_component_AvatarFallback, null, {
                                                            default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString(game.name), 1)
                                                            ]),
                                                            _: 2
                                                        }, 1024)
                                                    ]),
                                                    _: 2
                                                }, 1024),
                                                _createElementVNode("div", _hoisted_5, [
                                                    _createElementVNode("p", _hoisted_6, _toDisplayString(game.title), 1),
                                                    _createElementVNode("p", _hoisted_7, " $" + _toDisplayString(game.bet), 1)
                                                ])
                                            ], 8, _hoisted_4)
                                        ]));
                                    }), 128))
                                ]),
                                _: 1
                            }))
                            : _createCommentVNode("", true),
                        ($props.isLoading)
                            ? (_openBlock(), _createBlock(_component_CardContent, {
                                key: 1,
                                class: "tw-grid tw-grid-cols-1 md:tw-grid-cols-2 xl:tw-grid-cols-3 2xl:tw-grid-cols-4 tw-gap-4 tw-mt-6"
                            }, {
                                default: _withCtx(() => [
                                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (index) => {
                                        return _createElementVNode("div", {
                                            key: 'skeleton-' + index,
                                            class: "tw-flex tw-items-center tw-gap-4 2xl:tw-gap-3 tw-border tw-border-gray-300 tw-rounded tw-p-3 xl:tw-py-3 xl:tw-px-1.5 2xl:tw-p-3 tw-animate-pulse"
                                        }, _cache[0] || (_cache[0] = [
                                            _createElementVNode("div", { class: "tw-bg-gray-300 tw-rounded-md tw-h-16 tw-w-16" }, null, -1),
                                            _createElementVNode("div", { class: "tw-space-y-2 tw-flex-1" }, [
                                                _createElementVNode("div", { class: "tw-bg-gray-300 tw-rounded-md tw-h-4 tw-w-3/4" }),
                                                _createElementVNode("div", { class: "tw-bg-gray-300 tw-rounded-md tw-h-4 tw-w-1/2" })
                                            ], -1)
                                        ]));
                                    }), 64))
                                ]),
                                _: 1
                            }))
                            : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_Button, {
                                disabled: $props.isLoading,
                                class: _normalizeClass([
                                    'tw-mt-4 tw-w-[140px] tw-h-[43px]',
                                    $props.isLoading ? 'tw-animate-pulse tw-bg-slate-700' : 'tw-bg-blue-500'
                                ]),
                                onClick: $options.handleClick
                            }, {
                                default: _withCtx(() => [
                                    (!$props.isLoading)
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                            _cache[1] || (_cache[1] = _createTextVNode(_toDisplayString("Load More") + " ")),
                                            _createVNode(_component_Icon, {
                                                name: "Plus",
                                                strokeWidth: 2,
                                                size: 18,
                                                class: "me-2"
                                            })
                                        ], 64))
                                        : _createCommentVNode("", true)
                                ]),
                                _: 1
                            }, 8, ["disabled", "class", "onClick"])
                        ])
                    ]),
                    _: 1
                })
            ])
        ])
    ]));
}
