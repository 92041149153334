import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = { class: "log-reg" };
const _hoisted_2 = { class: "container" };
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col-lg-6" };
const _hoisted_5 = {
    id: "registerMod",
    class: "modal fade show",
    "aria-modal": "true",
    role: "dialog",
    style: { "display": "block" }
};
const _hoisted_6 = { class: "modal-dialog modal-dialog-centered" };
const _hoisted_7 = { class: "modal-content" };
const _hoisted_8 = { class: "modal-header justify-content-center" };
const _hoisted_9 = { class: "modal-body" };
const _hoisted_10 = { class: "form-area" };
const _hoisted_11 = { class: "section-text text-center" };
const _hoisted_12 = { class: "input-area d-flex align-items-center" };
const _hoisted_13 = {
    key: 0,
    class: "error_message"
};
const _hoisted_14 = { class: "input-area d-flex align-items-center" };
const _hoisted_15 = {
    key: 1,
    class: "error_message"
};
const _hoisted_16 = { class: "input-area d-flex align-items-center" };
const _hoisted_17 = {
    key: 2,
    class: "error_message"
};
const _hoisted_18 = { class: "input-area d-flex align-items-center" };
const _hoisted_19 = {
    key: 3,
    class: "error_message"
};
const _hoisted_20 = {
    key: 4,
    class: "error_message text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, [
                                _createElementVNode("div", _hoisted_8, [
                                    _createElementVNode("button", {
                                        type: "button",
                                        class: "btn-close",
                                        onClick: _cache[0] || (_cache[0] =
                                            //@ts-ignore
                                            (...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
                                    })
                                ]),
                                _createElementVNode("div", _hoisted_9, [
                                    _createElementVNode("div", _hoisted_10, [
                                        _createElementVNode("form", {
                                            onSubmit: _cache[6] || (_cache[6] = _withModifiers(
                                            //@ts-ignore
                                            (...args) => (_ctx.register && _ctx.register(...args)), ["prevent"]))
                                        }, [
                                            _createElementVNode("div", _hoisted_11, [
                                                _cache[8] || (_cache[8] = _createElementVNode("h3", { class: "title" }, " Registration ", -1)),
                                                _createElementVNode("p", null, [
                                                    _cache[7] || (_cache[7] = _createTextVNode(" Already a member? ")),
                                                    _createElementVNode("a", {
                                                        href: "javascript:void(0)",
                                                        class: "reg-btn",
                                                        onClick: _cache[1] || (_cache[1] =
                                                            //@ts-ignore
                                                            (...args) => (_ctx.showLogin && _ctx.showLogin(...args)))
                                                    }, " Login ")
                                                ])
                                            ]),
                                            _createElementVNode("div", _hoisted_12, [
                                                _cache[9] || (_cache[9] = _createElementVNode("img", {
                                                    src: "/assets/images/icon/email-icon.png",
                                                    alt: "icon"
                                                }, null, -1)),
                                                _withDirectives(_createElementVNode("input", {
                                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.formData.username) = $event)),
                                                    type: "text",
                                                    placeholder: "Username",
                                                    autocomplete: "off"
                                                }, null, 512), [
                                                    [_vModelText, _ctx.formData.username]
                                                ])
                                            ]),
                                            (_ctx.errors.username)
                                                ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.errors.username), 1))
                                                : _createCommentVNode("", true),
                                            _createElementVNode("div", _hoisted_14, [
                                                _cache[10] || (_cache[10] = _createElementVNode("img", {
                                                    src: "/assets/images/icon/email-icon.png",
                                                    alt: "icon"
                                                }, null, -1)),
                                                _withDirectives(_createElementVNode("input", {
                                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.formData.email) = $event)),
                                                    type: "email",
                                                    placeholder: "Email",
                                                    autocomplete: "off"
                                                }, null, 512), [
                                                    [_vModelText, _ctx.formData.email]
                                                ])
                                            ]),
                                            (_ctx.errors.email)
                                                ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_ctx.errors.email), 1))
                                                : _createCommentVNode("", true),
                                            _createElementVNode("div", _hoisted_16, [
                                                _cache[11] || (_cache[11] = _createElementVNode("img", {
                                                    src: "/assets/images/icon/security.png",
                                                    alt: "icon"
                                                }, null, -1)),
                                                _withDirectives(_createElementVNode("input", {
                                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.formData.password) = $event)),
                                                    type: "password",
                                                    placeholder: "Password",
                                                    autocomplete: "off"
                                                }, null, 512), [
                                                    [_vModelText, _ctx.formData.password]
                                                ])
                                            ]),
                                            (_ctx.errors.password)
                                                ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(_ctx.errors.password), 1))
                                                : _createCommentVNode("", true),
                                            _createElementVNode("div", _hoisted_18, [
                                                _cache[12] || (_cache[12] = _createElementVNode("img", {
                                                    src: "/assets/images/icon/security.png",
                                                    alt: "icon"
                                                }, null, -1)),
                                                _withDirectives(_createElementVNode("input", {
                                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((_ctx.formData.confirmPassword) = $event)),
                                                    type: "password",
                                                    placeholder: "Confirm Password",
                                                    autocomplete: "off"
                                                }, null, 512), [
                                                    [_vModelText, _ctx.formData.confirmPassword]
                                                ])
                                            ]),
                                            (_ctx.errors.confirmPassword)
                                                ? (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(_ctx.errors.confirmPassword), 1))
                                                : _createCommentVNode("", true),
                                            (_ctx.backendError.message)
                                                ? (_openBlock(), _createElementBlock("p", _hoisted_20, _toDisplayString(_ctx.backendError.message), 1))
                                                : _createCommentVNode("", true),
                                            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "btn-area text-center" }, [
                                                _createElementVNode("button", {
                                                    type: "submit",
                                                    class: "cmn-btn mt-4"
                                                }, [
                                                    _createTextVNode(" Register "),
                                                    _createElementVNode("i", { class: "icon-d-right-arrow-2" })
                                                ])
                                            ], -1))
                                        ], 32)
                                    ])
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ])
    ]));
}
