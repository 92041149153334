import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, isRef as _isRef, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useVModel } from "@vueuse/core";
import { cn } from "@/lib/utils";
export default /*@__PURE__*/ _defineComponent({
    __name: 'Input',
    props: {
        defaultValue: {},
        modelValue: {},
        class: {},
        error: { type: Boolean }
    },
    emits: ["update:modelValue"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emits = __emit;
        const modelValue = useVModel(props, "modelValue", emits, {
            passive: true,
            defaultValue: props.defaultValue
        });
        return (_ctx, _cache) => {
            return _withDirectives((_openBlock(), _createElementBlock("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (_isRef(modelValue) ? (modelValue).value = $event : null)),
                class: _normalizeClass(_unref(cn)('tw-flex tw-w-full tw-rounded-lg tw-border tw-border-border tw-bg-card tw-p-3 tw-text-sm tw-ring-offset-background file:tw-border-0 file:tw-bg-transparent file:tw-text-sm file:tw-font-medium placeholder:tw-text-muted focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2 focus:tw-outline-none disabled:tw-cursor-not-allowed disabled:tw-opacity-50', { 'tw-border-red-600 focus:tw-ring-red-600 placeholder:tw-text-red-600': props.error }, props.class))
            }, null, 2)), [
                [_vModelText, _unref(modelValue)]
            ]);
        };
    }
});
