import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoginPopup = _resolveComponent("LoginPopup");
    const _component_RegisterPopup = _resolveComponent("RegisterPopup");
    const _component_WelcomeBonusPopup = _resolveComponent("WelcomeBonusPopup");
    const _component_DepositPopup = _resolveComponent("DepositPopup");
    const _component_WithdrawPopup = _resolveComponent("WithdrawPopup");
    const _component_ForgotPasswordPopup = _resolveComponent("ForgotPasswordPopup");
    const _component_HeaderComponent = _resolveComponent("HeaderComponent");
    const _component_router_view = _resolveComponent("router-view");
    const _component_FooterComponent = _resolveComponent("FooterComponent");
    return (_openBlock(), _createElementBlock("main", {
        class: _normalizeClass({ 'modal-open': $setup.activePopup }),
        style: _normalizeStyle($setup.activePopup ? 'overflow: hidden; padding-right: 6px;' : '')
    }, [
        ($setup.activePopup === 'login')
            ? (_openBlock(), _createBlock(_component_LoginPopup, {
                key: 0,
                onClose: $setup.hidePopup
            }, null, 8, ["onClose"]))
            : _createCommentVNode("", true),
        ($setup.activePopup === 'register')
            ? (_openBlock(), _createBlock(_component_RegisterPopup, {
                key: 1,
                onClose: $setup.hidePopup
            }, null, 8, ["onClose"]))
            : _createCommentVNode("", true),
        ($setup.activePopup === 'welcome-bonus')
            ? (_openBlock(), _createBlock(_component_WelcomeBonusPopup, {
                key: 2,
                onClose: $setup.hidePopup
            }, null, 8, ["onClose"]))
            : _createCommentVNode("", true),
        ($setup.activePopup === 'deposit')
            ? (_openBlock(), _createBlock(_component_DepositPopup, {
                key: 3,
                onClose: $setup.hidePopup
            }, null, 8, ["onClose"]))
            : _createCommentVNode("", true),
        ($setup.activePopup === 'withdraw')
            ? (_openBlock(), _createBlock(_component_WithdrawPopup, {
                key: 4,
                onClose: $setup.hidePopup
            }, null, 8, ["onClose"]))
            : _createCommentVNode("", true),
        ($setup.activePopup === 'forgotPassword')
            ? (_openBlock(), _createBlock(_component_ForgotPasswordPopup, {
                key: 5,
                onClose: $setup.hidePopup
            }, null, 8, ["onClose"]))
            : _createCommentVNode("", true),
        _createElementVNode("div", null, [
            _createVNode(_component_HeaderComponent),
            _createElementVNode("div", null, [
                _createVNode(_component_router_view)
            ]),
            _createVNode(_component_FooterComponent)
        ])
    ], 6));
}
