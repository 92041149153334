import { useDark } from "@vueuse/core";
import { onMounted, ref, watch } from "vue";
export function useLogo() {
    const isDark = useDark();
    const logo = ref("/logos/UKO-dark-version.png");
    watch(isDark, (newValue) => {
        if (newValue)
            logo.value = "/logos/UKO-light-version.png";
        else
            logo.value = "/logos/UKO-dark-version.png";
    });
    onMounted(() => {
        if (isDark.value)
            logo.value = "/logos/UKO-light-version.png";
        else
            logo.value = "/logos/UKO-dark-version.png";
    });
    return logo;
}
