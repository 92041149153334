export const allGames = [
    {
        "id": 1,
        "name": "AgeOfPrivateersGT",
        "title": "Age of Privateers",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 1,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 2,
        "name": "AlchemistsSecretGT",
        "title": "Alchemists Secret",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 2,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 3,
        "name": "AlwaysHotCubesGT",
        "title": "Always Hot Cubes",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 3,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 4,
        "name": "AlwaysHotDXGT",
        "title": "Always Hot",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 4,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 5,
        "name": "AmazingFruitsGT",
        "title": "Amazing Fruits",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 5,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 6,
        "name": "AmazingSevensGT",
        "title": "Amazing Sevens",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 6,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 7,
        "name": "AmazingStarsGT",
        "title": "Amazing Stars",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 7,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 8,
        "name": "AmericanGangsterGT",
        "title": "American Gangster",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 8,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 9,
        "name": "AttilaDX",
        "title": "Attila",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 9,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 10,
        "name": "BananasGoBahamasDX",
        "title": "Bananas go Bahamas",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 10,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 11,
        "name": "BananaSplashDX",
        "title": "Banana Splash",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 11,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 12,
        "name": "BankRaidGT",
        "title": "Bank Raid",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 12,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 13,
        "name": "BarsAndSevensGT",
        "title": "Bars and Sevens",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 13,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 14,
        "name": "BeachHolidaysGT",
        "title": "Beach Holidays",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 14,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 15,
        "name": "BlazingRichesGT",
        "title": "Blazing Riches",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 15,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 16,
        "name": "BookOfRaCL",
        "title": "Book of Ra",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 16,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 17,
        "name": "BookOfRaDXGT",
        "title": "Book of Ra",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 17,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 18,
        "name": "BurningHot7GT",
        "title": "Burning Hot 7",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 18,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 19,
        "name": "CaribbeanHolidaysGT",
        "title": "Caribbean Holidays",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 19,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 20,
        "name": "DazzlingDiamondsGT",
        "title": "Dazzling Diamonds",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 20,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 21,
        "name": "Diamond7GT",
        "title": "Diamond 7",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 21,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 22,
        "name": "DolphinsPearlCL",
        "title": "Dolphins Pearl",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 22,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 23,
        "name": "DolphinsPearlDXGT",
        "title": "Dolphins Pearl",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 23,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 24,
        "name": "ElvenPrincessGT",
        "title": "Elven Princess",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 24,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 25,
        "name": "ExtremeRichesGT",
        "title": "Extreme Riches",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 25,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 26,
        "name": "FlameDancerGT",
        "title": "Flame Dancer",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 26,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 27,
        "name": "FlamencoRosesGT",
        "title": "Flamenco Roses",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 27,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 28,
        "name": "FruitFortuneGT",
        "title": "Fruit Fortune",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 28,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 29,
        "name": "FruitSensationGT",
        "title": "Fruit Sensation",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 29,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 30,
        "name": "FruitsnSevensGT",
        "title": "Fruitsn Sevens",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 30,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 31,
        "name": "FruitsnSevensDXGT",
        "title": "Fruitsn Sevens",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 31,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 32,
        "name": "FruitsRoyalsGT",
        "title": "Fruits Royals",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 32,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 33,
        "name": "GardenOfRichesGT",
        "title": "Garden of Riches",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 33,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 34,
        "name": "GoldenReelGT",
        "title": "Golden Reel",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 34,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 35,
        "name": "GoldenSevensGT",
        "title": "Golden Sevens",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 35,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 36,
        "name": "GoldenSevensDXGT",
        "title": "GoldenS evens",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 36,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 37,
        "name": "GorgeousGoddessGT",
        "title": "Gorgeous Goddess",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 37,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 38,
        "name": "GorillaGT",
        "title": "Gorilla",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 38,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 39,
        "name": "GrandJesterGT",
        "title": "Grand Jester",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 39,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 40,
        "name": "GryphonsGoldDX",
        "title": "Gryphons Gold",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 40,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 41,
        "name": "Helena",
        "title": "Helena",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 41,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 42,
        "name": "HoffmeisterGT",
        "title": "Hoffmeister",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 42,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 43,
        "name": "JackpotCrownGT",
        "title": "Jackpot Crown",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 43,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 44,
        "name": "JestersCrownGT",
        "title": "Jesters Crown",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 44,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 45,
        "name": "JollyFruitsGT",
        "title": "Jolly Fruits",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 45,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 46,
        "name": "JollyReelsGT",
        "title": "Jolly Reels",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 46,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 47,
        "name": "JustJewelsDX",
        "title": "Just JewelsDX",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 47,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 48,
        "name": "KatanaGT",
        "title": "Katana",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 48,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 49,
        "name": "KingOfCardsDXGT",
        "title": "King of Cards",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 49,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    },
    {
        "id": 50,
        "name": "KingsJesterGT",
        "title": "Kings Jester",
        "shop_id": 0,
        "jpg_id": 0,
        "label": null,
        "device": 2,
        "gamebank": "slots",
        "chanceFirepot1": null,
        "chanceFirepot2": null,
        "chanceFirepot3": null,
        "fireCount1": null,
        "fireCount2": null,
        "fireCount3": null,
        "lines_percent_config_spin": "{\"line1\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_spin_bonus": "{\"line1_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line3_bonus\":{\"74_80\":\"15\",\"82_88\":\"9\",\"90_96\":\"7\"},\"line5_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line7_bonus\":{\"74_80\":\"12\",\"82_88\":\"8\",\"90_96\":\"6\"},\"line9_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"},\"line10_bonus\":{\"74_80\":\"10\",\"82_88\":\"7\",\"90_96\":\"5\"}}",
        "lines_percent_config_bonus": "{\"line1\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "lines_percent_config_bonus_bonus": "{\"line1_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line3_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line5_bonus\":{\"74_80\":\"100\",\"82_88\":\"50\",\"90_96\":\"40\"},\"line7_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line9_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"},\"line10_bonus\":{\"74_80\":\"50\",\"82_88\":\"40\",\"90_96\":\"30\"}}",
        "rezerv": "4",
        "cask": "",
        "advanced": "",
        "bet": "0.01, 0.02, 0.05, 0.10, 0.20",
        "scaleMode": "",
        "slotViewState": "",
        "view": 1,
        "denomination": "1.00",
        "category_temp": null,
        "original_id": 50,
        "bids": 0,
        "stat_in": "0.0000",
        "stat_out": "0.0000"
    }
];
