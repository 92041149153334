import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed } from "vue";
import { AccordionHeader, AccordionTrigger } from "radix-vue";
import { ChevronDown } from "lucide-vue-next";
import { cn } from "@/lib/utils";
export default /*@__PURE__*/ _defineComponent({
    __name: 'AccordionTrigger',
    props: {
        asChild: { type: Boolean },
        as: {},
        class: {}
    },
    setup(__props) {
        const props = __props;
        const delegatedProps = computed(() => {
            const { class: _, ...delegated } = props;
            return delegated;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(AccordionHeader), { class: "flex" }, {
                default: _withCtx(() => [
                    _createVNode(_unref(AccordionTrigger), _mergeProps(delegatedProps.value, {
                        class: _unref(cn)('tw-flex tw-flex-1 tw-items-center tw-justify-between tw-p-4 tw-text-sm tw-font-medium tw-transition-all [&[data-state=open]>svg]:tw-rotate-180', props.class)
                    }), {
                        default: _withCtx(() => [
                            _renderSlot(_ctx.$slots, "default"),
                            _renderSlot(_ctx.$slots, "icon", {}, () => [
                                _createVNode(_unref(ChevronDown), { class: "tw-h-4 w-4 tw-text-muted tw-shrink-0 tw-transition-transform tw-duration-200" })
                            ])
                        ]),
                        _: 3
                    }, 16, ["class"])
                ]),
                _: 3
            }));
        };
    }
});
