import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import GamesPage from '@/views/GamesPage.vue';
import AboutPage from '@/views/AboutPage.vue';
import CasinoPage from '@/views/CasinoPage.vue';
import HistoryPage from '@/views/HistoryPage.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import TermsOfService from '@/views/TermsOfService.vue';
import RegisterUser from '@/views/RegisterUser.vue';
const routes = [
    {
        path: '/',
        component: DefaultLayout,
        children: [
            {
                path: '/',
                name: 'IndexPage',
                component: HomePage,
            },
            {
                path: '/home',
                name: 'HomePage',
                component: HomePage,
            },
            {
                path: '/games',
                name: 'GamesPage',
                component: GamesPage,
            },
            {
                path: '/about',
                name: 'AboutPage',
                component: AboutPage,
            },
            {
                path: '/casino/:id',
                name: 'CasinoPage',
                component: CasinoPage,
            },
            {
                path: '/history',
                name: 'HistoryPage',
                component: HistoryPage,
            },
            {
                path: '/privacy-policy',
                name: 'PrivacyPolicy',
                component: PrivacyPolicy,
            },
            {
                path: '/terms-of-service',
                name: 'TermsOfService',
                component: TermsOfService,
            },
            {
                path: '/register-user',
                name: 'RegisterUser',
                component: RegisterUser,
            },
        ]
    }
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});
export default router;
