import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue";
const _hoisted_1 = { class: "popular-game" };
const _hoisted_2 = { class: "overlay pt-120 pb-120" };
const _hoisted_3 = { class: "container" };
const _hoisted_4 = { class: "row wow fadeInUp" };
const _hoisted_5 = { class: "games-carousel" };
const _hoisted_6 = { class: "single-box" };
const _hoisted_7 = ["href"];
const _hoisted_8 = ["src", "alt"];
const _hoisted_9 = { class: "col-lg-12" };
const _hoisted_10 = { class: "btn-area mt-40 text-center" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Slide = _resolveComponent("Slide");
    const _component_Carousel = _resolveComponent("Carousel");
    const _component_router_link = _resolveComponent("router-link");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "abs-item" }, [
                _createElementVNode("img", {
                    src: "/assets/images/popular-game-item.png",
                    alt: "icon"
                })
            ], -1)),
            _createElementVNode("div", _hoisted_3, [
                _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"row justify-content-center wow fadeInUp\"><div class=\"col-lg-6 text-center\"><div class=\"section-text\"><h5 class=\"sub-title\"> Easy way for crypto Play </h5><h2 class=\"title\"> Popular Game </h2><p> Unparalleled slots to give lovers of the gambling world an exciting gaming experience par excellence </p></div></div></div>", 1)),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_Carousel, _normalizeProps(_guardReactiveProps($setup.config)), {
                            default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.games, (game) => {
                                    return (_openBlock(), _createBlock(_component_Slide, {
                                        key: game.id,
                                        class: "single"
                                    }, {
                                        default: _withCtx(() => [
                                            _createElementVNode("div", _hoisted_6, [
                                                _createElementVNode("a", {
                                                    href: $setup.user?.username ? `${$setup.Const.URL}/game/${game.name}/?api_exit=/` : undefined,
                                                    target: "_blank",
                                                    onClick: _cache[0] || (_cache[0] = ($event) => (!$setup.user?.username && $event.preventDefault()))
                                                }, [
                                                    _createElementVNode("img", {
                                                        src: `${$setup.Const.URL}/frontend/Default/ico/${game.name}.jpg`,
                                                        alt: game.title
                                                    }, null, 8, _hoisted_8)
                                                ], 8, _hoisted_7),
                                                _createElementVNode("p", null, _toDisplayString(game.title), 1)
                                            ])
                                        ]),
                                        _: 2
                                    }, 1024));
                                }), 128))
                            ]),
                            _: 1
                        }, 16)
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                            _createVNode(_component_router_link, {
                                to: "/games",
                                class: "cmn-btn"
                            }, {
                                default: _withCtx(() => _cache[1] || (_cache[1] = [
                                    _createTextVNode(" All Games "),
                                    _createElementVNode("i", { class: "icon-d-right-arrow-2" }, null, -1)
                                ])),
                                _: 1
                            })
                        ])
                    ])
                ])
            ])
        ])
    ]));
}
