<template>
  <div class="history" />
  History Screen
</template>


<style scoped>
  .history {
    padding-top: 136px;
  }
</style>