import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import '@/assets/css/bootstrap.min.css';
import '@/assets/css/fontawesome.min.css';
import '@/assets/css/jquery-ui.css';
import '@/assets/css/plugin/nice-select.css';
import '@/assets/css/plugin/magnific-popup.css';
import '@/assets/css/plugin/slick.css';
import '@/assets/css/ara-font.css';
import '@/assets/css/plugin/animate.css';
import '@/assets/css/style.css';
import 'vue3-carousel/dist/carousel.css';
import '@/assets/css/tailwind.css';
import Vue3Toastify from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import mitt from 'mitt';
import { useAuthStore } from './stores/authStore';
const initializeAuth = () => {
    const authStore = useAuthStore();
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
        authStore.setUser(JSON.parse(storedUser)); // Restore user state
    }
};
const app = createApp(App);
const pinia = createPinia();
app.use(Vue3Toastify);
app.use(router);
app.use(pinia);
initializeAuth();
const emitter = mitt();
app.config.globalProperties.emitter = emitter;
app.mount('#app');
