import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = { class: "log-reg" };
const _hoisted_2 = { class: "container" };
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col-lg-6" };
const _hoisted_5 = {
    id: "forgotPasswordMod",
    class: "modal fade show",
    "aria-modal": "true",
    role: "dialog",
    style: { "display": "block" }
};
const _hoisted_6 = { class: "modal-dialog modal-dialog-centered" };
const _hoisted_7 = { class: "modal-content" };
const _hoisted_8 = { class: "modal-header justify-content-center" };
const _hoisted_9 = { class: "modal-body" };
const _hoisted_10 = { class: "form-area" };
const _hoisted_11 = { class: "input-area d-flex align-items-center" };
const _hoisted_12 = {
    key: 0,
    class: "error-message"
};
const _hoisted_13 = {
    key: 1,
    class: "success-message"
};
const _hoisted_14 = { class: "btn-area text-center" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, [
                                _createElementVNode("div", _hoisted_8, [
                                    _createElementVNode("button", {
                                        type: "button",
                                        class: "btn-close",
                                        "data-bs-dismiss": "modal",
                                        "aria-label": "Close",
                                        onClick: _cache[0] || (_cache[0] =
                                            //@ts-ignore
                                            (...args) => ($setup.closePopup && $setup.closePopup(...args)))
                                    })
                                ]),
                                _createElementVNode("div", _hoisted_9, [
                                    _createElementVNode("div", _hoisted_10, [
                                        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "section-text text-center" }, [
                                            _createElementVNode("h3", { class: "title" }, " Forgot Password "),
                                            _createElementVNode("p", null, "Enter your email address to reset your password.")
                                        ], -1)),
                                        _createElementVNode("div", _hoisted_11, [
                                            _cache[3] || (_cache[3] = _createElementVNode("img", {
                                                src: "/assets/images/icon/email-icon.png",
                                                alt: "icon"
                                            }, null, -1)),
                                            _withDirectives(_createElementVNode("input", {
                                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => (($setup.email) = $event)),
                                                type: "email",
                                                placeholder: "Email",
                                                autocomplete: "off",
                                                required: ""
                                            }, null, 512), [
                                                [_vModelText, $setup.email]
                                            ])
                                        ]),
                                        ($setup.errorMessage)
                                            ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString($setup.errorMessage), 1))
                                            : _createCommentVNode("", true),
                                        ($setup.successMessage)
                                            ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString($setup.successMessage), 1))
                                            : _createCommentVNode("", true),
                                        _createElementVNode("div", _hoisted_14, [
                                            _createElementVNode("button", {
                                                type: "button",
                                                class: "cmn-btn mt-4",
                                                onClick: _cache[2] || (_cache[2] =
                                                    //@ts-ignore
                                                    (...args) => ($setup.submitForgotPassword && $setup.submitForgotPassword(...args)))
                                            }, _cache[4] || (_cache[4] = [
                                                _createTextVNode(" Submit "),
                                                _createElementVNode("i", { class: "icon-d-right-arrow-2" }, null, -1)
                                            ]))
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ])
    ]));
}
