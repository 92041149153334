import { defineComponent as _defineComponent } from 'vue';
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed } from "vue";
import * as icons from "lucide-vue-next";
// ==============================================================
export default /*@__PURE__*/ _defineComponent({
    __name: 'icon',
    props: {
        name: {},
        size: {},
        color: {},
        strokeWidth: {},
        defaultClass: {}
    },
    setup(__props) {
        const props = __props;
        const icon = computed(() => {
            return icons[props.name];
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(icon.value), {
                size: _ctx.size,
                color: _ctx.color,
                "stroke-width": _ctx.strokeWidth,
                "default-class": _ctx.defaultClass
            }, null, 8, ["size", "color", "stroke-width", "default-class"]));
        };
    }
});
