import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { AccordionRoot, useForwardPropsEmits, } from 'radix-vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'Accordion',
    props: {
        collapsible: { type: Boolean },
        disabled: { type: Boolean },
        dir: {},
        orientation: {},
        asChild: { type: Boolean },
        as: {},
        type: {},
        modelValue: {},
        defaultValue: {}
    },
    emits: ["update:modelValue"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emits = __emit;
        const forwarded = useForwardPropsEmits(props, emits);
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(AccordionRoot), _normalizeProps(_guardReactiveProps(_unref(forwarded))), {
                default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
            }, 16));
        };
    }
});
