import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "tw-flex tw-w-full tw-gap-4 tw-flex-col lg:tw-flex-row tw-justify-between" };
const _hoisted_2 = { class: "tw-flex tw-flex-col tw-justify-between lg:tw-w-[33%] tw-border tw-rounded-[16px] tw-px-[15px] tw-pb-5 tw-overflow-hidden tw-border-[#c5991e]" };
const _hoisted_3 = {
    key: 0,
    class: "tw-flex tw-items-center tw-justify-center tw-gap-2 tw-mt-8 lg:tw-mt-[51px]"
};
const _hoisted_4 = {
    key: 1,
    class: "tw-flex tw-items-center tw-justify-center tw-gap-2 tw-mt-6 lg:tw-mt-[51px]"
};
const _hoisted_5 = { class: "tw-w-full tw-h-full xl:tw-rounded-2xl lg:tw-rounded-lg md:tw-rounded-xl tw-rounded-lg tw-overflow-hidden" };
const _hoisted_6 = ["src", "alt"];
const _hoisted_7 = { class: "tw-h-auto tw-w-full tw-max-w-[992px] tw-relative" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_CasinoCarousel = _resolveComponent("CasinoCarousel");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _cache[1] || (_cache[1] = _createElementVNode("div", null, [
                _createElementVNode("h1", { class: "tw-text-2xl lg:tw-text-[2vw] lg:tw-tracking-[-1.96px] tw-font-black tw-text-center tw-leading-normal tw-mt-[51px]" }, " Jockpot "),
                _createElementVNode("p", { class: "tw-text-[5vw] md:tw-text-[51px] lg:tw-text-[3vw] 2xl:tw-text-[51px] tw-font-black tw-text-[#ffe55d] tw-text-center tw-leading-normal" }, " 208599$ ")
            ], -1)),
            ($props.isLoading && $props.games.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (index) => {
                        return _createElementVNode("div", {
                            key: 'skeleton-' + index,
                            class: "tw-flex tw-items-center tw-gap-2 tw-w-1/4 2xl:tw-h-24 min-[1440px]:tw-h-[55px] xl:tw-h-[55px] lg:tw-h-10 md:tw-h-16 sm:tw-h-14 tw-h-10"
                        }, _cache[0] || (_cache[0] = [
                            _createElementVNode("div", { class: "tw-w-full tw-h-full xl:tw-rounded-2xl lg:tw-rounded-lg md:tw-rounded-xl tw-rounded-lg tw-overflow-hidden" }, [
                                _createElementVNode("div", { class: "tw-bg-gray-300 tw-w-full tw-h-full tw-rounded-2xl tw-animate-pulse" })
                            ], -1)
                        ]));
                    }), 64))
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.games.slice(0, 4), (game) => {
                        return (_openBlock(), _createElementBlock("div", {
                            key: game.id,
                            class: "tw-flex tw-items-center tw-gap-2 tw-w-1/4 2xl:tw-h-24 min-[1440px]:tw-h-[55px] xl:tw-h-[55px] lg:tw-h-auto"
                        }, [
                            _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("img", {
                                    src: `https://azurecoinz.com/frontend/Default/ico/${game.name}.jpg`,
                                    alt: game.title,
                                    class: "tw-w-full tw-h-full"
                                }, null, 8, _hoisted_6)
                            ])
                        ]));
                    }), 128))
                ]))
        ]),
        _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_CasinoCarousel)
        ])
    ]));
}
