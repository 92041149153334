import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { AvatarImage } from "radix-vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'AvatarImage',
    props: {
        src: {},
        referrerPolicy: {},
        asChild: { type: Boolean },
        as: {}
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(AvatarImage), _mergeProps(props, { class: "tw-object-cover tw-w-full tw-h-full" }), null, 16));
        };
    }
});
