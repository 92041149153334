import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "log-reg" };
const _hoisted_2 = { class: "container" };
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col-lg-6" };
const _hoisted_5 = {
    id: "loginMod",
    class: "modal fade show",
    "aria-modal": "true",
    role: "dialog",
    style: { "display": "block" }
};
const _hoisted_6 = { class: "modal-dialog modal-dialog-centered" };
const _hoisted_7 = { class: "modal-content" };
const _hoisted_8 = { class: "modal-header justify-content-center" };
const _hoisted_9 = { class: "tab-content" };
const _hoisted_10 = {
    id: "loginArea",
    class: "tab-pane fade show active",
    role: "tabpanel",
    "aria-labelledby": "loginArea-tab"
};
const _hoisted_11 = { class: "login-reg-content" };
const _hoisted_12 = { class: "modal-body" };
const _hoisted_13 = { class: "form-area" };
const _hoisted_14 = { class: "section-text text-center" };
const _hoisted_15 = { class: "input-area d-flex align-items-center" };
const _hoisted_16 = { class: "input-area d-flex align-items-center" };
const _hoisted_17 = { class: "checkbox-item d-flex justify-content-between" };
const _hoisted_18 = { class: "checkbox-single d-flex align-items-center" };
const _hoisted_19 = { class: "content-area" };
const _hoisted_20 = { class: "checkbox-area d-flex" };
const _hoisted_21 = {
    key: 0,
    class: "error-message"
};
const _hoisted_22 = {
    key: 1,
    class: "success-message"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, [
                                _createElementVNode("div", _hoisted_8, [
                                    _createElementVNode("button", {
                                        type: "button",
                                        class: "btn-close",
                                        "data-bs-dismiss": "modal",
                                        "aria-label": "Close",
                                        onClick: _cache[0] || (_cache[0] =
                                            //@ts-ignore
                                            (...args) => ($options.closePopup && $options.closePopup(...args)))
                                    })
                                ]),
                                _cache[14] || (_cache[14] = _createElementVNode("ul", { class: "nav log-reg-btn d-none justify-content-around" }, [
                                    _createElementVNode("li", {
                                        class: "bottom-area",
                                        role: "presentation"
                                    }, [
                                        _createElementVNode("button", {
                                            id: "regArea-tab",
                                            class: "nav-link",
                                            "data-bs-toggle": "tab",
                                            "data-bs-target": "#regArea",
                                            type: "button",
                                            role: "tab",
                                            "aria-controls": "regArea",
                                            "aria-selected": "false"
                                        }, " SIGN UP ")
                                    ]),
                                    _createElementVNode("li", {
                                        class: "bottom-area",
                                        role: "presentation"
                                    }, [
                                        _createElementVNode("button", {
                                            id: "loginArea-tab",
                                            class: "nav-link active",
                                            "data-bs-toggle": "tab",
                                            "data-bs-target": "#loginArea",
                                            type: "button",
                                            role: "tab",
                                            "aria-controls": "loginArea",
                                            "aria-selected": "true"
                                        }, " LOGIN ")
                                    ])
                                ], -1)),
                                _createElementVNode("div", _hoisted_9, [
                                    _createElementVNode("div", _hoisted_10, [
                                        _createElementVNode("div", _hoisted_11, [
                                            _createElementVNode("div", _hoisted_12, [
                                                _createElementVNode("div", _hoisted_13, [
                                                    _createElementVNode("form", {
                                                        onSubmit: _cache[5] || (_cache[5] = _withModifiers(
                                                        //@ts-ignore
                                                        (...args) => ($options.login && $options.login(...args)), ["prevent"]))
                                                    }, [
                                                        _createElementVNode("div", _hoisted_14, [
                                                            _cache[7] || (_cache[7] = _createElementVNode("h3", { class: "title" }, " Welcome Back ", -1)),
                                                            _createElementVNode("p", null, [
                                                                _cache[6] || (_cache[6] = _createTextVNode(" Not a member? ")),
                                                                _createElementVNode("a", {
                                                                    href: "javascript:void(0)",
                                                                    class: "reg-btn",
                                                                    onClick: _cache[1] || (_cache[1] =
                                                                        //@ts-ignore
                                                                        (...args) => ($options.displayRegisterPopup && $options.displayRegisterPopup(...args)))
                                                                }, "Register")
                                                            ])
                                                        ]),
                                                        _createElementVNode("div", _hoisted_15, [
                                                            _cache[8] || (_cache[8] = _createElementVNode("img", {
                                                                src: "/assets/images/icon/email-icon.png",
                                                                alt: "icon"
                                                            }, null, -1)),
                                                            _withDirectives(_createElementVNode("input", {
                                                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => (($data.credentials.username) = $event)),
                                                                type: "email",
                                                                placeholder: "Email",
                                                                autocomplete: "off",
                                                                required: ""
                                                            }, null, 512), [
                                                                [_vModelText, $data.credentials.username]
                                                            ])
                                                        ]),
                                                        _createElementVNode("div", _hoisted_16, [
                                                            _cache[9] || (_cache[9] = _createElementVNode("img", {
                                                                src: "/assets/images/icon/security.png",
                                                                alt: "icon"
                                                            }, null, -1)),
                                                            _withDirectives(_createElementVNode("input", {
                                                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => (($data.credentials.password) = $event)),
                                                                type: "password",
                                                                placeholder: "Password",
                                                                autocomplete: "off",
                                                                required: ""
                                                            }, null, 512), [
                                                                [_vModelText, $data.credentials.password]
                                                            ])
                                                        ]),
                                                        _createElementVNode("div", _hoisted_17, [
                                                            _createElementVNode("label", _hoisted_18, [
                                                                _createElementVNode("span", _hoisted_19, [
                                                                    _createElementVNode("span", _hoisted_20, [
                                                                        _withDirectives(_createElementVNode("input", {
                                                                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => (($data.rememberMe) = $event)),
                                                                            type: "checkbox"
                                                                        }, null, 512), [
                                                                            [_vModelCheckbox, $data.rememberMe]
                                                                        ]),
                                                                        _cache[10] || (_cache[10] = _createElementVNode("span", { class: "checkmark" }, null, -1))
                                                                    ]),
                                                                    _cache[11] || (_cache[11] = _createElementVNode("span", { class: "item-title d-flex align-items-center" }, [
                                                                        _createElementVNode("span", null, "Remember me")
                                                                    ], -1))
                                                                ])
                                                            ])
                                                        ]),
                                                        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "btn-area text-center" }, [
                                                            _createElementVNode("button", {
                                                                type: "submit",
                                                                class: "cmn-btn mt-4"
                                                            }, [
                                                                _createTextVNode(" Login "),
                                                                _createElementVNode("i", { class: "icon-d-right-arrow-2" })
                                                            ])
                                                        ], -1))
                                                    ], 32),
                                                    ($data.errorMessage)
                                                        ? (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString($data.errorMessage), 1))
                                                        : _createCommentVNode("", true),
                                                    ($data.successMessage)
                                                        ? (_openBlock(), _createElementBlock("p", _hoisted_22, _toDisplayString($data.successMessage), 1))
                                                        : _createCommentVNode("", true)
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _cache[13] || (_cache[13] = _createElementVNode("div", {
                                        id: "regArea",
                                        class: "tab-pane fade",
                                        role: "tabpanel",
                                        "aria-labelledby": "regArea-tab"
                                    }, [
                                        _createElementVNode("div", { class: "login-reg-content regMode" }, [
                                            _createElementVNode("div", { class: "modal-body" }, [
                                                _createElementVNode("div", { class: "form-area" }, [
                                                    _createElementVNode("form", { action: "#" })
                                                ])
                                            ])
                                        ])
                                    ], -1))
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ])
    ]));
}
