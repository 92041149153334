import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed } from "vue";
import { Label } from "radix-vue";
import { cn } from "@/lib/utils";
export default /*@__PURE__*/ _defineComponent({
    __name: 'Label',
    props: {
        for: {},
        asChild: { type: Boolean },
        as: {},
        class: {}
    },
    setup(__props) {
        const props = __props;
        const delegatedProps = computed(() => {
            const { class: _, ...delegated } = props;
            return delegated;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(Label), _mergeProps(delegatedProps.value, {
                class: _unref(cn)('tw-leading-none peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70', props.class)
            }), {
                default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
            }, 16, ["class"]));
        };
    }
});
