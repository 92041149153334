import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    key: 1,
    role: "alert",
    class: "input-error tw-inline-block tw-mt-2 tw-ml-2 tw-text-xs tw-text-red-500"
};
import { useField } from "vee-validate";
// SHADCN COMPONENTS
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
// ==============================================================
export default /*@__PURE__*/ _defineComponent({
    __name: 'TextField',
    props: {
        id: {},
        inputClass: {},
        name: {},
        label: {},
        placeholder: {},
        isDisable: { type: Boolean, default: false },
        type: { default: "text" }
    },
    setup(__props) {
        const props = __props;
        const { value, errorMessage } = useField(() => props.name);
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", null, [
                (_ctx.label)
                    ? (_openBlock(), _createBlock(_unref(Label), {
                        key: 0,
                        for: _ctx.id,
                        class: "inline-block mb-3 text-sm font-medium"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.label), 1)
                        ]),
                        _: 1
                    }, 8, ["for"]))
                    : _createCommentVNode("", true),
                _createVNode(_unref(Input), {
                    id: _ctx.id,
                    "model-value": _unref(value),
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (_isRef(value) ? (value).value = $event : null)),
                    step: "0.01",
                    type: _ctx.type,
                    disabled: _ctx.isDisable,
                    error: !!_unref(errorMessage),
                    placeholder: _ctx.placeholder,
                    class: _normalizeClass(_ctx.inputClass)
                }, null, 8, ["id", "model-value", "type", "disabled", "error", "placeholder", "class"]),
                (_unref(errorMessage))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_unref(errorMessage)), 1))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
