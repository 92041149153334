import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "tw-pb-4 tw-space-y-6" };
const _hoisted_2 = { class: "tw-space-y-3" };
const _hoisted_3 = { class: "tw-flex tw-gap-3 hover:tw-text-primary" };
const _hoisted_4 = { class: "tw-group group-hover:tw-text-primary tw-text-[#738499] tw-text-[13px] tw-font-medium tw-truncate" };
const _hoisted_5 = { class: "group-hover:tw-text-primary tw-text-[#738499] tw-text-[13px] tw-font-medium tw-truncate" };
import Scrollbar from "simplebar-vue";
import { useRoute, RouterLink } from "vue-router";
import { useLogo } from "@/hooks/useLogo";
import { useSidebar } from "@/stores/sidebar";
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar/index';
const __default__ = {
    name: "CasinoComponent",
    props: {
        gameCategories: {
            type: Array,
            required: true,
        },
    },
};
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    setup(__props) {
        const route = useRoute();
        const logo = useLogo();
        const { handleCloseSidebar } = useSidebar();
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(Scrollbar), null, {
                default: _withCtx(() => [
                    _createElementVNode("nav", _hoisted_1, [
                        _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("ul", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.gameCategories, (gameCat) => {
                                    return (_openBlock(), _createElementBlock("li", {
                                        key: gameCat.title,
                                        class: "tw-transition-all tw-duration-300 tw-ease-in-out tw-border-l-2 tw-cursor-pointer tw-text-[#738499] tw-group tw-border-l-transparent hover:tw-font-medium hover:tw-border-l-primary hover:tw-text-primary hover:tw-bg-hover"
                                    }, [
                                        _createVNode(_unref(RouterLink), {
                                            to: gameCat.href,
                                            class: "tw-flex tw-justify-between tw-p-4 hover:tw-text-primary tw-text-[#738499] tw-text-[13px] tw-font-medium tw-truncate tw-items-center"
                                        }, {
                                            default: _withCtx(() => [
                                                _createElementVNode("div", _hoisted_3, [
                                                    _createVNode(_unref(Avatar), {
                                                        shape: "square",
                                                        size: "base",
                                                        class: "tw-max-h-[30px] tw-w-[30px] tw-bg-transparent"
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createVNode(_unref(AvatarImage), {
                                                                class: "!tw-object-contain",
                                                                src: `${'https://azurecoinz.com/1wrri/providers/small/'}${gameCat.href}${'.svg'}`,
                                                                alt: "game"
                                                            }, null, 8, ["src"]),
                                                            _createVNode(_unref(AvatarFallback), null, {
                                                                default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(gameCat.title), 1)
                                                                ]),
                                                                _: 2
                                                            }, 1024)
                                                        ]),
                                                        _: 2
                                                    }, 1024),
                                                    _createElementVNode("p", _hoisted_4, _toDisplayString(gameCat.title), 1)
                                                ]),
                                                _createElementVNode("p", _hoisted_5, _toDisplayString(gameCat.count), 1)
                                            ]),
                                            _: 2
                                        }, 1032, ["to"])
                                    ]));
                                }), 128))
                            ])
                        ])
                    ])
                ]),
                _: 1
            }));
        };
    }
});
