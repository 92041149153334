import { cva } from "class-variance-authority";
export { default as Button } from "./Button.vue";
export const buttonVariants = cva("tw-inline-flex tw-items-center tw-justify-center tw-whitespace-nowrap tw-rounded-md tw-text-sm tw-font-medium tw-ring-offset-background tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-pointer-events-none disabled:tw-opacity-50", {
    variants: {
        variant: {
            default: "tw-bg-primary tw-text-white hover:tw-bg-primary/90",
            outline: "tw-border tw-border-border tw-bg-transparent",
            link: "tw-text-primary tw-underline-offset-4 hover:tw-underline"
            // destructive: "tw-bg-destructive tw-text-destructive-foreground hover:tw-bg-destructive/90",
            // secondary: "tw-bg-secondary tw-text-secondary-foreground hover:tw-bg-secondary/80",
            // ghost: "hover:tw-bg-accent hover:tw-text-accent-foreground",
        },
        size: {
            default: "tw-h-10 tw-px-5 tw-rounded-md",
            xs: "tw-h-7 tw-px-2 tw-rounded",
            sm: "tw-h-9 tw-px-3 tw-rounded-md",
            lg: "tw-h-12 tw-px-8 tw-rounded-md",
            icon: "tw-h-10 tw-w-10"
        },
        color: {
            default: "!tw-bg-primary tw-text-white hover:tw-bg-primary/90",
            error: "tw-bg-error tw-text-white hover:tw-bg-error/90",
            success: "tw-bg-success tw-text-white hover:tw-bg-success/90",
            warning: "tw-bg-warning tw-text-white hover:tw-bg-warning/90",
            info: "tw-bg-info tw-text-white hover:tw-bg-info/90"
        }
    },
    compoundVariants: [
        // Outline Buttons
        {
            variant: "outline",
            color: "default",
            class: "tw-text-primary tw-bg-transparent tw-border-primary tw-outline-none hover:tw-bg-primary/10"
        },
        {
            variant: "outline",
            color: "info",
            class: "tw-text-info tw-bg-transparent tw-border-info tw-outline-none hover:tw-bg-info/10"
        },
        {
            variant: "outline",
            color: "success",
            class: "tw-text-success tw-bg-transparent tw-border-success tw-outline-none hover:tw-bg-success/10"
        },
        {
            variant: "outline",
            color: "warning",
            class: "tw-text-warning tw-bg-transparent tw-border-warning tw-outline-none hover:tw-bg-warning/10"
        },
        {
            variant: "outline",
            color: "error",
            class: "tw-text-error tw-bg-transparent tw-border-error tw-outline-none hover:tw-bg-error/10"
        },
        // Links
        {
            variant: "link",
            color: "default",
            class: "tw-text-primary tw-bg-transparent tw-h-auto tw-px-0 hover:tw-bg-transparent"
        },
        {
            variant: "link",
            color: "error",
            class: "tw-text-error tw-bg-transparent tw-h-auto tw-px-0 hover:tw-bg-transparent"
        },
        {
            variant: "link",
            color: "success",
            class: "tw-text-success tw-bg-transparent tw-h-auto tw-px-0 hover:tw-bg-transparent"
        },
        {
            variant: "link",
            color: "info",
            class: "tw-text-info tw-bg-transparent tw-h-auto tw-px-0 hover:tw-bg-transparent"
        },
        {
            variant: "link",
            color: "warning",
            class: "tw-text-warning tw-bg-transparent tw-h-auto hover:tw-bg-transparent"
        }
    ],
    defaultVariants: { variant: "default", size: "default", color: "default" }
});
