import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "tw-w-full tw-h-full tw-relative slider_wrapper" };
const _hoisted_2 = { class: "single-box" };
const _hoisted_3 = ["src"];
const _hoisted_4 = { class: "single-box" };
const _hoisted_5 = ["src"];
const _hoisted_6 = { class: "single-box" };
const _hoisted_7 = ["src"];
const _hoisted_8 = { class: "single-box" };
const _hoisted_9 = ["src"];
const _hoisted_10 = { class: "tw-w-full tw-absolute tw-bottom-2.5 tw-flex tw-justify-end tw-pr-2.5 tw-gap-1" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Slide = _resolveComponent("Slide");
    const _component_Carousel = _resolveComponent("Carousel");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Carousel, _mergeProps($data.config, {
            ref: "carousel",
            class: "carousel-wrapper",
            loop: true
        }), {
            default: _withCtx(() => [
                _createVNode(_component_Slide, { class: "single" }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("img", {
                                src: `${$data.Const.URL}/frontend/Default/slider/ferrari_296.jpg`,
                                alt: "ferrari_296"
                            }, null, 8, _hoisted_3)
                        ])
                    ]),
                    _: 1
                }),
                _createVNode(_component_Slide, { class: "single" }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("img", {
                                src: `${$data.Const.URL}/frontend/Default/slider/zero_girl.jpg`,
                                alt: "zero_girl"
                            }, null, 8, _hoisted_5)
                        ])
                    ]),
                    _: 1
                }),
                _createVNode(_component_Slide, { class: "single" }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("img", {
                                src: `${$data.Const.URL}/frontend/Default/slider/1win.png`,
                                alt: "1win"
                            }, null, 8, _hoisted_7)
                        ])
                    ]),
                    _: 1
                }),
                _createVNode(_component_Slide, { class: "single" }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("img", {
                                src: `${$data.Const.URL}/frontend/Default/slider/footbal_poster.jpg`,
                                alt: "footbal_poster"
                            }, null, 8, _hoisted_9)
                        ])
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 16),
        _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", {
                class: "swiper-button-prev",
                onClick: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => ($options.prevSlide && $options.prevSlide(...args)))
            }, " < "),
            _createElementVNode("div", {
                class: "swiper-button-next",
                onClick: _cache[1] || (_cache[1] =
                    //@ts-ignore
                    (...args) => ($options.nextSlide && $options.nextSlide(...args)))
            }, " > ")
        ])
    ]));
}
