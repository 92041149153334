import { defineStore } from "pinia";
import axios from "axios";
import Const from "@/const";
const xsrfToken = document.cookie
    .split("; ")
    .find((row) => row.startsWith("XSRF-TOKEN="))
    ?.split("=")[1];
axios.defaults.baseURL = Const.URL;
const isLocalhost = window.location.hostname === "localhost";
// axios.defaults.baseURL = 'http://localhost:8080/';
// axios.defaults.withCredentials = true;
// axios.defaults.headers.common["Accept"] = "application/json, text/plain, */*";
// axios.defaults.headers.common["Content-Type"] = "application/json";
// axios.defaults.headers.common["X-XSRF-TOKEN"] = xsrfToken;
// axios.defaults.timeout = 10000; // Timeout in milliseconds
let ApiUrl = `http://localhost:8080/api/`;
if (isLocalhost) {
    ApiUrl = `http://localhost:8080/api/`;
}
else {
    ApiUrl = Const.URL;
}
export const useCasinoStore = defineStore("casino", {
    state: () => ({
        games: [],
        gameCategories: [],
        page: 0,
    }),
    actions: {
        async fetchGames(page = 0, id = 'all') {
            // this.games = games;
            try {
                // const response = await axios.post(`/get_casino_game`, {
                //     id: 10,
                //     page,
                // });
                // const response1 = await axios.get(`${Const.URL}/get_casino_game`, {
                //         headers: {
                //             "Accept": "application/json, text/plain, */*",
                //             "Content-Type": "application/json",
                //             "X-XSRF-TOKEN": xsrfToken, // Replace dynamically if needed
                //         },
                //         timeout: 10000,
                //         withCredentials: true,
                //     }
                // );
                // const response = await axios.post(`${Const.URL}/get_casino_game`, {
                //     headers: {
                //         "Accept": "application/json, text/plain, */*",
                //         "Content-Type": "application/json",
                //         "X-XSRF-TOKEN": xsrfToken,
                //     },
                //     timeout: 10000,
                //     withCredentials: true,
                // });
                if (isLocalhost) {
                    let testConstUrl = `get_casino_game.json`;
                    if (page === 1) {
                        testConstUrl = `get_casino_game1.json`;
                    }
                    else if (page === 2) {
                        testConstUrl = `get_casino_game2.json`;
                    }
                    const apiPath = `${ApiUrl}/${testConstUrl}`;
                    // const response = await axios.post(`${Const.URL}/get_casino_game`, {
                    const response = await axios.get(`${apiPath}`, {
                        // id: 10,
                        // page,
                        headers: {
                            "Accept": "application/json, text/plain, */*",
                            "Content-Type": "application/json",
                            "X-XSRF-TOKEN": xsrfToken,
                        },
                        timeout: 100000,
                        withCredentials: true,
                    });
                    if (page === 0) {
                        this.games = response.data;
                    }
                    else {
                        this.games = [...this.games, ...response.data];
                    }
                }
                else {
                    const response = await axios.post(`${Const.URL}/get_casino_game`, {
                        id,
                        page,
                        headers: {
                            "Accept": "application/json, text/plain, */*",
                            "Content-Type": "application/json",
                            "X-XSRF-TOKEN": xsrfToken,
                        },
                        timeout: 100000,
                        withCredentials: true,
                    });
                    if (page === 0) {
                        this.games = response.data;
                    }
                    else {
                        this.games = [...this.games, ...response.data];
                    }
                    // const headers = {
                    //     'Accept': 'application/json, text/plain, */*',
                    //     'Accept-Language': 'en-US,en;q=0.9,uk-UA;q=0.8,uk;q=0.7,pl;q=0.6',
                    //     'Cache-Control': 'no-cache',
                    //     'Connection': 'keep-alive',
                    //     'Content-Type': 'application/json',
                    //     'Cookie':
                    //         'remember_web_59ba36addc2b2f9401580f014c7f58ea4e30989d=eyJpdiI6ImpnUDhDZWNhZmlwUnhJczdCdjY3cEE9PSIsInZhbHVlIjoiTXNqeWFqVm1UeXJjSEFMMm5wS3liajlWL2Z6QWQvZklBQldlNytpbGJWUmorSDhZWU1ZL21qRURCVUR3UW8yVTN5V0lFczB4cS90MVpYcDF6N2VRZjh0N1c2aitLb1FGSW1Xd3FpYzFTSkVqTUtuSVkvL04xMDR4NW9MRUtFM1d2SXc3T0lRMldLSndRQjVMSTlkcGxoTEpQM1NvUEFmRDZkcFV2RXE4d2Q5a3N0VUpMM09zOTY2dHIxZzVxWnM4Q3M5clFmTU1YLzZUM3pOU2xzY0plS1VMODNTN3Q3Ym9RbC90MnVlNHRzRT0iLCJtYWMiOiI4MjA5OGQxOWUzZGZlZGJkMzY3MGE4MmI4OTA2YzU5ZmQyZDllMzc1ZTgxYzUxMzA5NzgyOTgzNzcyM2M0ZGIzIn0%3D; XSRF-TOKEN=eyJpdiI6Ijl6a1pndjl5cVpucG1lSWluTFNUZFE9PSIsInZhbHVlIjoiRUJobyt3aENlUFEvczJ1SXJNWDRIbEx6RmdnYi8rUkpZM0lKRG1lMUdGeXEzSFRiYlhhK091cDJnc2pYZXI1K2pHY1l3Sm94YkR1eDVibGZ0TUFXM0hzKzlJR1lUTVdoNzhxT2NHMXpueGFQNVh6VEY2dkNWaTVDcHBCb1lwemciLCJtYWMiOiIwYjQ3ZmU2NGU2ZjFlZDY1NDIwMGQyYzFlMDdlYWFkM2YzN2QzNmNmNjA1MmYxNWMyYmVkOWMzNzU3ZDk4YjU5In0%3D; laravel_session=eyJpdiI6InN0UHFVTExkLzdwaU01cTNLVENldkE9PSIsInZhbHVlIjoiWDlsRk5nUnVWdTMzU1lKclllbG1KYXkxdkpHbEU5cXNPcGlBVks3ZGpBdmpRbFBiYVRhRWJRVC9RMjZ0N2xndTdlZndaZnRDdHNsOVBHWmVOUFgyallMWkNNQ0ZNNThHdldaTjliNUhhWWFxdUNWU0ZxVG84V3g1OWkrTWZ5c3IiLCJtYWMiOiIyYzk3ZWFiOWFiMzk4YjBiNmRkZWM4MGEzMmFhMzc5NTBjMjhjYTA3ZTE4YzAxMWE4YzRhYjRjNzVlMjY5YjJhIn0%3D',
                    //     'DNT': '1',
                    //     'Origin': 'https://azurecoinz.com',
                    //     'Pragma': 'no-cache',
                    //     'Referer': 'https://azurecoinz.com/home',
                    //     'Sec-Fetch-Dest': 'empty',
                    //     'Sec-Fetch-Mode': 'cors',
                    //     'Sec-Fetch-Site': 'same-origin',
                    //     'User-Agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/131.0.0.0 Safari/537.36',
                    //     'X-XSRF-TOKEN':
                    //         'eyJpdiI6Im0xQXNsbEIydkF5QUVSQ1BoR3YrbGc9PSIsInZhbHVlIjoiM3E0ME5JTzcvNDVhS3FjUDg4aHRHVjAyeEdyQ3JtNWRrT1hmcFJhYkdEdXBPRDlPY0NUSnRoWE0wTzE3MnN5Um5NL2MvUVR2ZklTc2RySlp2ZGFTZ3dGcExWdWpSTjJpdlVBczBNVUxNcnA4bUZFMlpkN2N1MVo1VEpJQTE0c3IiLCJtYWMiOiI0ZDZkY2Y5MTk4NmM2MzA4MjhkZDhmNmJkM2UzZjAzZDJkODQ5OGM2YTVlNjY2NzZhYWM2Yzk4M2U5YWJiNDgyIiwidGFnIjoiIn0',
                    //     'sec-ch-ua': '"Google Chrome";v="131", "Chromium";v="131", "Not_A Brand";v="24"',
                    //     'sec-ch-ua-mobile': '?0',
                    //     'sec-ch-ua-platform': '"macOS"',
                    //     'timeout': '10000',
                    //     credentials: 'include',
                    // };
                    // const body = {};
                    // const url = Const.URL;
                    // try {
                    //     const response = await fetch('https://azurecoinz.com/get_casino_game', {
                    //         method: 'POST',
                    //         headers,
                    //         body: JSON.stringify(body),
                    //     });
                    //     if (!response.ok) {
                    //         throw new Error(`HTTP error! status: ${response.status}`);
                    //     }
                    //     const data = await response.json();
                    //     this.serverData = data; // Update the component's state with the fetched data
                    // } catch (error) {
                    //     console.error('Error fetching data:', error);
                    // }
                    // const response1 = await axios.post(`/get_casino_game`, {
                    //     id: 10,
                    //     page,
                    //     headers,
                    //     timeout: 100000,
                    //     withCredentials: true,
                    // });
                }
            }
            catch (error) {
                console.error("Error fetching games:", error.message);
            }
        },
        async fetchGameCategories() {
            try {
                if (isLocalhost) {
                    const testConstUrl = `http://localhost:8080/api`;
                    // const response = await axios.post(`${Const.URL}/get_provider`, {
                    const response = await axios.get(`${testConstUrl}/get_provider.json`, {
                        headers: {
                            "Accept": "application/json, text/plain, */*",
                            "Content-Type": "application/json",
                            "X-XSRF-TOKEN": xsrfToken,
                        },
                        timeout: 10000,
                        withCredentials: true,
                    });
                    this.gameCategories = response.data;
                }
                else {
                    const response = await axios.post(`${Const.URL}/get_provider`, {
                        headers: {
                            "Accept": "application/json, text/plain, */*",
                            "Content-Type": "application/json",
                            "X-XSRF-TOKEN": xsrfToken,
                        },
                        timeout: 10000,
                        withCredentials: true,
                    });
                    this.gameCategories = response.data;
                }
            }
            catch (error) {
                console.error("Error fetching game categories:", error.message);
            }
        },
        incrementPage() {
            this.page++;
        },
        clearGames() {
            this.games = [];
            this.page = 0;
        }
    },
});
