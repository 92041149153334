import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "footer-section" };
const _hoisted_2 = { class: "container" };
const _hoisted_3 = { class: "footer-area pt-120" };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "col-xl-12" };
const _hoisted_6 = { class: "footer-top d-flex align-items-center justify-content-between" };
const _hoisted_7 = { class: "footer-box" };
const _hoisted_8 = { class: "footer-link d-flex align-items-center gap-4" };
const _hoisted_9 = { class: "footer-bottom" };
const _hoisted_10 = { class: "row justify-content-between align-items-center" };
const _hoisted_11 = { class: "col-xl-3 col-lg-5 d-flex justify-content-center justify-content-lg-end" };
const _hoisted_12 = { class: "social" };
const _hoisted_13 = { class: "footer-link gap-2 d-flex align-items-center" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_link = _resolveComponent("router-link");
    return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_router_link, { to: "/" }, {
                                default: _withCtx(() => _cache[0] || (_cache[0] = [
                                    _createElementVNode("img", {
                                        src: "/assets/images/logo.png",
                                        class: "logo",
                                        alt: "logo"
                                    }, null, -1)
                                ])),
                                _: 1
                            }),
                            _createElementVNode("div", _hoisted_7, [
                                _createElementVNode("ul", _hoisted_8, [
                                    _createElementVNode("li", null, [
                                        _createVNode(_component_router_link, { to: "/about-us" }, {
                                            default: _withCtx(() => _cache[1] || (_cache[1] = [
                                                _createTextVNode(" About Us ")
                                            ])),
                                            _: 1
                                        })
                                    ]),
                                    _createElementVNode("li", null, [
                                        _createVNode(_component_router_link, { to: "/contact" }, {
                                            default: _withCtx(() => _cache[2] || (_cache[2] = [
                                                _createTextVNode(" Contact ")
                                            ])),
                                            _: 1
                                        })
                                    ]),
                                    _createElementVNode("li", null, [
                                        _createVNode(_component_router_link, { to: "/privacy-policy" }, {
                                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                                                _createTextVNode(" Privacy Policy ")
                                            ])),
                                            _: 1
                                        })
                                    ]),
                                    _createElementVNode("li", null, [
                                        _createVNode(_component_router_link, { to: "/terms-of-service" }, {
                                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                                                _createTextVNode(" Terms of Service ")
                                            ])),
                                            _: 1
                                        })
                                    ])
                                ])
                            ])
                        ])
                    ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "col-lg-7 d-flex justify-content-center justify-content-lg-start order-lg-0 order-1" }, [
                            _createElementVNode("div", { class: "copyright text-center" }, [
                                _createElementVNode("p", null, "© Azurecoinz 2024. All rights reserved.")
                            ])
                        ], -1)),
                        _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("div", _hoisted_12, [
                                _createElementVNode("ul", _hoisted_13, [
                                    _createElementVNode("li", null, [
                                        _createVNode(_component_router_link, { to: "/javascript:void(0)" }, {
                                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                                                _createElementVNode("i", { class: "fb fab fa-facebook-f" }, null, -1)
                                            ])),
                                            _: 1
                                        })
                                    ]),
                                    _createElementVNode("li", null, [
                                        _createVNode(_component_router_link, { to: "/javascript:void(0)" }, {
                                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                                                _createElementVNode("i", { class: "ins fab fa-instagram" }, null, -1)
                                            ])),
                                            _: 1
                                        })
                                    ]),
                                    _createElementVNode("li", null, [
                                        _createVNode(_component_router_link, { to: "/javascript:void(0)" }, {
                                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                                                _createElementVNode("i", { class: "tw fab fa-twitter" }, null, -1)
                                            ])),
                                            _: 1
                                        })
                                    ]),
                                    _createElementVNode("li", null, [
                                        _createVNode(_component_router_link, { to: "/javascript:void(0)" }, {
                                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                                                _createElementVNode("i", { class: "in fab fa-linkedin-in" }, null, -1)
                                            ])),
                                            _: 1
                                        })
                                    ])
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ])
    ]));
}
