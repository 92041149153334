import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed } from "vue";
import { AccordionItem, useForwardProps } from "radix-vue";
import { cn } from "@/lib/utils";
export default /*@__PURE__*/ _defineComponent({
    __name: 'AccordionItem',
    props: {
        disabled: { type: Boolean },
        value: {},
        asChild: { type: Boolean },
        as: {},
        class: {}
    },
    setup(__props) {
        const props = __props;
        const delegatedProps = computed(() => {
            const { class: _, ...delegated } = props;
            return delegated;
        });
        const forwardedProps = useForwardProps(delegatedProps);
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(AccordionItem), _mergeProps(_unref(forwardedProps), {
                class: _unref(cn)('tw-border tw-border-border tw-rounded-lg tw-mb-4 last-of-type:tw-mb-0', props.class)
            }), {
                default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
            }, 16, ["class"]));
        };
    }
});
