import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { cn } from "../../../lib/utils";
export default /*@__PURE__*/ _defineComponent({
    __name: 'Card',
    props: {
        class: {}
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_unref(cn)('tw-rounded-xl tw-shadow-sm tw-border tw-bg-card tw-border-[#F1F6F9] tw-text-card-foreground tw-dark:border-gray-700/30', props.class))
            }, [
                _renderSlot(_ctx.$slots, "default")
            ], 2));
        };
    }
});
