import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed } from "vue";
import { AccordionContent } from "radix-vue";
import { cn } from "@/lib/utils";
export default /*@__PURE__*/ _defineComponent({
    __name: 'AccordionContent',
    props: {
        forceMount: { type: Boolean },
        asChild: { type: Boolean },
        as: {},
        class: {}
    },
    setup(__props) {
        const props = __props;
        const delegatedProps = computed(() => {
            const { class: _, ...delegated } = props;
            return delegated;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(AccordionContent), _mergeProps(delegatedProps.value, { class: "tw-overflow-hidden tw-text-sm tw-font-normal tw-text-muted tw-transition-all data-[state=closed]:tw-animate-accordion-up data-[state=open]:tw-animate-accordion-down" }), {
                default: _withCtx(() => [
                    _createElementVNode("div", {
                        class: _normalizeClass(_unref(cn)('px-4 pb-4', props.class))
                    }, [
                        _renderSlot(_ctx.$slots, "default")
                    ], 2)
                ]),
                _: 3
            }, 16));
        };
    }
});
